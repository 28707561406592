import React from 'react';

import ReactDOM from 'react-dom';
const ModalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
  backgroundColor: 'white',
  padding: '50px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
  overflow: 'auto',
  maxHeight: '500px',
};

const OverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.7)',
  zIndex: 1000,
};

const Modal = ({ children, title, isOpen, onClose }) => {
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <>
      <div style={OverlayStyle} onClick={onClose} />
      <div style={ModalStyle}>
        {children}
        <div className="btn btn-main" onClick={onClose}>
          סגור
        </div>
      </div>
    </>,
    document.getElementById('modal-root')
  );
};

export default Modal;

import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { extractErrorMessage } from '../../utils/general/utils';

import authService from './authService';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
  user: user ? user : null,
  isError: false,
  isLoading: false,
  errorMessage: '',
  isSuccess: false,
  token: null,
};

export const login = createAsyncThunk('auth/login', async (user, thunkApi) => {
  try {
    localStorage.clear();
    return await authService.login(user);
  } catch (error) {
    return thunkApi.rejectWithValue(extractErrorMessage(error));
  }
});

export const logOut = createAction('auth/logout', () => {
  authService.logOut();
  return {};
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
    logout: (state) => (state = initialState),
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.token = action.payload.accessToken;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload.message;
        state.user = null;
      });
  },
});
export const { reset } = authSlice.actions;

export default authSlice.reducer;

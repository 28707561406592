import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getTrips } from '../../features/trips/tripsSlice';
import Spinner from '../../components/Spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import TripItem from '../../components/TripItem';

const TripsList = ({ handleSelect }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { trips, isLoading } = useSelector((state) => state.trips);
  const { season } = useSelector((state) => state.organization);

  useEffect(() => {
    dispatch(getTrips())
      .unwrap()
      .catch((err) => {
        toast.error(err.message);
      });
  }, [dispatch, season]);

  if (isLoading) {
    return <Spinner />;
  }

  const title = `רשימת טיולים ל${season}`;

  return (
    <div className="container">
      <h2>{title}</h2>
      {trips &&
        trips.map((trip) => (
          <div key={trip._id} onClick={() => handleSelect(trip)}>
            <TripItem trip={trip} />
          </div>
        ))}
    </div>
  );
};
export default TripsList;

export function extractErrorMessage(error) {
  return error.response?.data?.message || error.message || error.toString();
}

export const clacDays = (date1, date2) => {
  const diffTime = Math.abs(new Date(date2) - new Date(date1));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

  export const removeDuplicatesByName = (arr) => {
    return arr?.filter(
      (v, i, a) =>
        a.findIndex((v2) =>
          ['firstName', 'lastName'].every((k) => v2[k] === v[k])
        ) === i
    );
  };
import api from '../../App/api';

//login user

const prefix = '/auth';

const login = async (user) => {
  const response = await api.post(`${prefix}/loginById`, user);
  return response.data;
};

//logout user

const logOut = () => {
  localStorage.clear();
  sessionStorage.clear();

  //clear local storage in case it is not supported by the browser

  window.localStorage.clear();
  window.sessionStorage.clear();
};

const authService = {
  login,
  logOut,
};

export default authService;

import api from '../../App/api';
import FileDownload from 'js-file-download';
const prefix = `/reports`;

const downloadReport = async (path, fileName) => {
  const response = await api.get(`${prefix}/downloadReport/${fileName}`, {
    responseType: 'blob',
  });
  return FileDownload(response.data, fileName);
};

const reportsService = {
  downloadReport,
};

export default reportsService;

import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { registerUserForRefund } from '../../features/trips/tripsSlice';
import {
  addBankAccount,
  getBankAccount,
  updateBankAccount,
} from '../../features/users/usersSlice';
import { bankAccountSchema } from '../../utils/validations/Schemas';

const Refund = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const [errorMsg, setErrorMsg] = useState('');
  const [hasBankDetails, setHasBankDetails] = useState(false);
  const [initialValues, setInitialValues] = useState({
    bankNumber: '',
    branchNumber: '',
    accountNumber: '',
    accountHolder: '',
  });

  useEffect(() => {
    //todo check if user have bank details
    dispatch(getBankAccount())
      .unwrap()
      .then((res) => {
        if (res.bankAccount) {
          setInitialValues(res.bankAccount);
          setHasBankDetails(true);
        }
      })
      .catch((err) => {
        setErrorMsg(err);
      });
  }, [user, dispatch]);

  return (
    <div className="container">
      <h1 className="heading">החזר כספי</h1>
      <p className="error"> {errorMsg}</p>
      <Formik
        // validationSchema={bankAccountSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          //todo dispatch refund
          const payload = {
            bankNumber: values.bankNumber,
            branchNumber: values.branchNumber,
            accountNumber: values.accountNumber,
            accountHolder: values.accountHolder,
          };
          if (hasBankDetails) {
            dispatch(updateBankAccount(payload))
              .unwrap()
              .then((res) => {
                console.log(res);
                toast.success('הפרטים עודכנו בהצלחה');
              })
              .catch((err) => {
                setErrorMsg(err);
              });
          } else {
            //dispatch addBankDetails and then dispatch refund signup
            dispatch(addBankAccount(payload))
              .unwrap()
              .then((res) => {
                console.log(res);
                toast.success('הפרטים עודכנו בהצלחה');
              })
              .catch((err) => {
                setErrorMsg(err);
              });
          }
          // dispatch(refundSignup(values));
          dispatch(registerUserForRefund());

          console.log(values);
        }}
        enableReinitialize={true}
      >
        {({ values, isValid, touched, handleSubmit, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="form-container">
                <div className="form-group">
                  <label htmlFor="bankNumber">מספר הבנק</label>
                  <Field
                    name="bankNumber"
                    type="number"
                    placeholder="מספר הבנק"
                    id="bankNumber"
                  />
                  {touched.bankNumber && !values.bankNumber && (
                    <p className="error">שדה חובה</p>
                  )}

                  <label htmlFor="branchNumber">מספר סניף</label>
                  <Field
                    name="branchNumber"
                    type="number"
                    placeholder="מספר סניף"
                    id="branchNumber"
                  />
                  {touched.branchNumber && !values.branchNumber && (
                    <p className="error">שדה חובה</p>
                  )}
                  <label htmlFor="accountNumber">מספר חשבון</label>
                  <Field
                    name="accountNumber"
                    type="number"
                    placeholder="מספר חשבון"
                    id="accountNumber"
                  />
                  {touched.accountNumber && !values.accountNumber && (
                    <p className="error">שדה חובה</p>
                  )}

                  <label htmlFor="accountHolder">שם בעל החשבון</label>
                  <Field
                    name="accountHolder"
                    type="text"
                    placeholder="שם בעל החשבון"
                    id="accountHolder"
                  />
                  {touched.accountHolder && !values.accountHolder && (
                    <p className="error">שדה חובה</p>
                  )}
                </div>
              </div>
              <div className="buttons-container">
                <button
                  className="btn btn-main"
                  type="submit"

                  // disabled={!isValid}
                >
                  שלח
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <p>נא לשים לב שפרטי הבנק מדוייקים כדי להימנע מאיחור בתשלום</p>
    </div>
  );
};
export default Refund;

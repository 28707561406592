import { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CyclesLIst from '../../components/CyclesLIst';
import {
  organizationSelector,
  setSeason,
} from '../../features/organization/organizationSlice';
import { downloadReport } from '../../features/reports/reportSlice';
import {
  generateTripReport,
  getRegisteredUsersPerTrip,
  setCycle,
  setTrip,
} from '../../features/trips/tripsSlice';
import TripsList from '../user/TripsList';
import { RenderSeasonsList } from './SelectSeasonAdmin';

const Reports = () => {
  const dispatch = useDispatch();
  const { report, seasons, season } = useSelector(organizationSelector);
  const [selectedTrip, setSelectedTrip] = useState(false);
  const [selectedCycle, setSelectedCycle] = useState(null);
  const [registrations, setRegistrations] = useState([]);
  const { trip } = useSelector((state) => state.trips);

  const SelectSeason = (season) => {
    dispatch(setSeason(season));
    setSelectedTrip(false);
    setSelectedCycle(false);
  };

  function RenderTripReg({ tripName, registrations }) {
    const generateReport = () => {
      console.log('generateReport');
      dispatch(generateTripReport()).unwrap().then(dispatch(downloadReport()));
    };

    return (
      <div>
        <div>
          <h3> {`שם טיול :${tripName}`} </h3>
          {registrations.length > 0 && (
            <>
              <button className="btn" onClick={generateReport}>
                הורד דוח
              </button>
            </>
          )}
          <div className="grid">
            <div>שם</div>
            <div>תאריכי מחזור </div>
            <div>מספר נפשות</div>
            {registrations.map((registration, idx) => (
              <Fragment key={idx}>
                <div>
                  {`${registration.firstName} ${registration.lastName}`}
                </div>
                <div>
                  {`${registration.returnDate} - ${registration.departDate}`}
                </div>
                <div> {`${registration.room}`}</div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const tripSection = () => {
    const selectTrip = (trip) => {
      console.log(trip);
      dispatch(setTrip(trip));
      setSelectedTrip((prev) => !prev);
    };
    const selectCycle = (cycle) => {
      dispatch(setCycle(cycle));
      dispatch(getRegisteredUsersPerTrip(cycle._id))
        .unwrap()
        .then((res) => {
          console.log(res.result);
          setRegistrations(res.result);
        });
      setSelectedCycle(true);
    };

    return (
      <div>
        {!selectedTrip && !Object.keys(season).length == 0 && (
          <TripsList handleSelect={selectTrip} />
        )}
        {!selectedCycle && selectedTrip && (
          <CyclesLIst handleSelect={selectCycle} />
        )}

        {selectedCycle && (
          <RenderTripReg
            registrations={registrations}
            tripName={trip.tripName}
          />
        )}
      </div>
    );
  };
  const voucherSection = () => {
    return (
      <div>
        <h2>דוח קופונים</h2>
      </div>
    );
  };
  const refundSection = () => {
    return (
      <div>
        <h2>דוח החזרות</h2>
      </div>
    );
  };
  const unusualSection = () => {
    return (
      <div>
        <h2>דוח טיולים מוזרים</h2>
      </div>
    );
  };
  const allRegisteredSection = () => {
    return (
      <div>
        <h2>דוח כל המשתתפים</h2>
      </div>
    );
  };
  const allUnregisteredSection = () => {
    return (
      <div>
        <h2>דוח כל המשתתפים לא רשומים</h2>
      </div>
    );
  };

  return (
    <>
      <div className="container">
        <h2>{`דוחות ${report}`}</h2>

        <RenderSeasonsList
          seasons={seasons}
          handleSelectSeason={SelectSeason}
        />
        {report === 'טיולים' && tripSection()}
        {report === 'וואוצ׳ר' && voucherSection()}
        {report === 'החזר כספי' && refundSection()}
        {report === 'חריגים' && unusualSection()}
        {report === 'כל הרשומים' && allRegisteredSection()}
        {report === 'לא רשומים' && allUnregisteredSection()}
      </div>
    </>
  );
};
export default Reports;

//make a react function component to display responsive table of all the logs
//and a button to download the logs as a csv file

// Path: src/pages/admin/Logs.jsx

import React, { useEffect } from 'react';
import {
  getLogsByUser,
  setCurrentPage,
  resetPage,
} from '../../features/logs/logsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getLogs } from '../../features/logs/logsSlice';
import Spinner from '../../components/Spinner';
import { toast } from 'react-toastify';
import UsersList from '../../components/UsersList';

const Logs = () => {
  const dispatch = useDispatch();
  const { logs, currentPage, totalPages, isLoading } = useSelector(
    (state) => state.logs
  );

  const { users } = useSelector((state) => state.users);

  const fetchAllLogs = () =>
    dispatch(getLogs())
      .unwrap()
      .catch((err) => {
        toast.error(err.message);
      });

  useEffect(() => {
    fetchAllLogs();
  }, []);

  useEffect(() => {
    fetchAllLogs();
  }, [currentPage]);

  const handleSelect = async (id) => {
    const userFound = users.find((user) => user._id === id);
    console.log(userFound);
    if (userFound) {
      dispatch(getLogsByUser(userFound._id));
    }
  };

  const clearSearch = () => {
    dispatch(resetPage());
    fetchAllLogs();
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="">
      <h2 className="text-center">לוגים</h2>

      <UsersList handleSelect={handleSelect} />
      <div className="text-center">
        <button
          className="btn btn-main"
          onClick={clearSearch}
          style={{
            marginBottom: '1rem',
          }}
        >
          אפס
        </button>
      </div>
      <div className="logsGrid">
        <div className="">severity</div>
        <div className="">type</div>
        <div className="">invokedBy</div>
        <div className="">affectedUser</div>
        <div className="">message</div>
        <div className="">createdAt date</div>
        <div className="">createdAt hour</div>
      </div>
      {logs &&
        logs.map((log) => (
          <div key={log._id} className="logsGrid">
            <div
              className={
                log.severity === 'error'
                  ? 'error'
                  : log.severity === 'success'
                  ? 'success'
                  : 'info'
              }
            >
              {log.severity}
            </div>
            <div className="">{log.type}</div>
            <div className="">{log.invokedBy}</div>
            <div className="">{log.affectedUser}</div>
            <div className="">{log.message}</div>
            <div className="">
              {new Date(log.createdAt).toISOString().slice(0, 10)}
            </div>
            <div className="">
              {new Date(log.createdAt).toISOString().slice(11, 19)}
            </div>
          </div>
        ))}

      {logs.length === 0 && (
        <div className="text-center">
          <h3>לא נמצאו תוצאות</h3>
        </div>
      )}
      <div className="logsGrid pages-container">
        <div className="no-border"></div>
        <div className="no-border"></div>
        <div className="no-border"></div>
        <div className="no-border"></div>
        {`עמוד ${currentPage} מתוך ${totalPages}`}

        <button
          className="btn btn-primary"
          onClick={() =>
            currentPage > 1 && dispatch(setCurrentPage(currentPage - 1))
          }
        >
          הקודם
        </button>
        <button
          className="btn btn-primary"
          onClick={() => dispatch(setCurrentPage(currentPage + 1))}
        >
          הבא
        </button>
      </div>
    </div>
  );
};

export default Logs;

import { useDispatch, useSelector } from 'react-redux';
import CycleForm from '../../components/CycleForm';

const AddCycle = () => {
  const dispatch = useDispatch();
  const { trip } = useSelector((state) => state.trips);

  const initialValues = {
    departDate: '',
    returnDate: '',
    days: '',
    nights: '',
    notes: '',
    availableSlots: '',
  };

  const Submit = (values) => {
    console.log(values);
    //todo: dispatch add cycle
  };

  return (
    <CycleForm
      initialValues={initialValues}
      btnText="הוסף מחזור חדש"
      Submit={Submit}
    />
  );
};
export default AddCycle;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { extractErrorMessage } from '../../utils/general/utils';
import logsService from './logsService';

const initialState = {
  logs: [],
  isLoading: false,
  limit: 10,
  currentPage: 1,
  totalPages: 0,
};

const getLogs = createAsyncThunk('logs/getLogs', async (_, thunkAPI) => {
  try {
    const { limit, currentPage: page } = thunkAPI.getState().logs;
    return await logsService.getLogs(limit, page);
  } catch (error) {
    return thunkAPI.rejectWithValue(extractErrorMessage(error));
  }
});

const getLogsByUser = createAsyncThunk(
  'logs/getLogsByUser',
  async (userId, thunkAPI) => {
    try {
      const { limit, currentPage: page } = thunkAPI.getState().logs;
      return await logsService.getLogsByUser(limit, page, userId);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    resetPage: (state) => {
      state.currentPage = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.logs = action.payload.logs;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getLogs.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getLogsByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLogsByUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.logs = action.payload.logs;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getLogsByUser.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setLimit, setCurrentPage, resetPage } = logsSlice.actions;
export { getLogs, getLogsByUser };

export default logsSlice.reducer;

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Field, Form } from 'formik';
import { registerSchema } from '../../utils/validations/Schemas';
import { createUser } from '../../features/users/usersSlice';
import { useEffect } from 'react';

const NewUser = () => {
  const { user } = useSelector((state) => state.auth);
  const { loading, isError, ErrorMessage } = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    if (isError) {
      toast.error(ErrorMessage);
    }
  }, [isError]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="">
      <Formik
        initialValues={{
          firstName: '',
          firstNameEng: '',
          lastName: '',
          lastNameEng: '',
          email: '',
          password: '',
          password2: '',
          employeeId: '',
          birthDate: '',
          isRetired: false,
          gender: '',
          street: '',
          homeNumber: '',
          neighborhood: '',
          city: '',
          phoneNumber: '',
          cellPhoneNumber: '',
          officePhoneNumber: '',
          isActive: true,
          ssn: '',
          passportExpiration: '',
          passportNumber: '',
          roleList: ['admin', 'user', 'manager'],
          role: '',
        }}
        validationSchema={registerSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const userData = {
            organization: user.organization,
            ...values,
          };

          dispatch(createUser(userData));
          // console.log(userData);
          setSubmitting(true);
          // dispatch(registerUser(userData))
        }}
      >
        {({ errors, touched, isSubmitting, isValid }) => (
          <Form>
            <div className="form-container">
              <div className="form-group">
                <label htmlFor="employeeId">מספר עובד </label>
                <Field
                  name="employeeId"
                  placeholder="מספר עובד"
                  id="employeeId"
                />
                {errors.employeeId && touched.employeeId ? (
                  <div className="input-feedback">{errors.employeeId}</div>
                ) : null}

                <label htmlFor="ssn">מספר ת.ז</label>
                <Field name="ssn" placeholder="מספר ת.ז" id="ssn" />
                {errors.ssn && touched.ssn ? (
                  <div className="input-feedback">{errors.ssn}</div>
                ) : null}
                <label htmlfor="firstName">שם פרטי</label>
                <Field
                  name="firstName"
                  type="text"
                  placeholder="שם פרטי"
                  id="firstName"
                />
                {errors.firstName && touched.firstName ? (
                  <div className="input-feedback">{errors.firstName}</div>
                ) : null}
                <label htmlFor="lastName">שם משפחה</label>
                <Field
                  name="lastName"
                  type="text"
                  placeholder="שם משפחה"
                  id="lastName"
                />
                {errors.lastName && touched.lastName ? (
                  <div className="input-feedback">{errors.lastName}</div>
                ) : null}
                <label htmlFor="firstNameEng">שם פרטי באנגלית</label>
                <Field
                  name="firstNameEng"
                  type="text"
                  placeholder="שם פרטי באנגלית"
                  id="firstNameEng"
                />
                {errors.firstNameEng && touched.firstNameEng ? (
                  <div className="input-feedback">{errors.firstNameEng}</div>
                ) : null}
                <label htmlFor="lastNameEng">שם משפחה באנגלית</label>
                <Field
                  name="lastNameEng"
                  type="text"
                  placeholder="שם משפחה באנגלית"
                  id="lastNameEng"
                />
                {errors.lastNameEng && touched.lastNameEng ? (
                  <div className="input-feedback">{errors.lastNameEng}</div>
                ) : null}

                <label htmlFor="birthDate">תאריך לידה</label>
                <Field name="birthDate" type="date" id="birthDate" />
                {errors.birthDate && touched.birthDate ? (
                  <div className="input-feedback">{errors.birthDate}</div>
                ) : null}
                <label htmlFor="gender">מין</label>
                <Field name="gender" component="select" id="gender">
                  <option value="male">זכר</option>
                  <option value="female">נקבה</option>
                </Field>
                {errors.gender && touched.gender ? (
                  <div className="input-feedback">{errors.gender}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="street">רחוב</label>
                <Field
                  name="street"
                  type="text"
                  placeholder="רחוב"
                  id="street"
                />
                {errors.street && touched.street ? (
                  <div className="input-feedback">{errors.street}</div>
                ) : null}

                <label htmlFor="homeNumber">מספר בית</label>
                <Field
                  name="homeNumber"
                  type="number"
                  placeholder="מספר בית"
                  id="homeNumber"
                />
                {errors.homeNumber && touched.homeNumber ? (
                  <div className="input-feedback">{errors.homeNumber}</div>
                ) : null}

                <label htmlFor="city">עיר</label>
                <Field name="city" type="text" placeholder="עיר" id="city" />
                {errors.city && touched.city ? (
                  <div className="input-feedback">{errors.city}</div>
                ) : null}

                <label htmlFor="neighborhood">שכונה</label>
                <Field
                  name="neighborhood"
                  type="text"
                  placeholder="שכונה"
                  id="neighborhood"
                />
                {errors.neighborhood && touched.neighborhood ? (
                  <div className="input-feedback">{errors.neighborhood}</div>
                ) : null}
                <label htmlFor="phoneNumber">טלפון</label>
                <Field
                  name="phoneNumber"
                  type="text"
                  placeholder="טלפון"
                  id="phoneNumber"
                />
                {errors.phoneNumber && touched.phoneNumber ? (
                  <div className="input-feedback">{errors.phoneNumber}</div>
                ) : null}
                <label htmlFor="cellPhoneNumber">טלפון נייד</label>
                <Field
                  name="cellPhoneNumber"
                  type="text"
                  placeholder="טלפון נייד"
                  id="cellPhone"
                />
                {errors.cellPhoneNumber && touched.cellPhoneNumber ? (
                  <div className="input-feedback">{errors.cellPhoneNumber}</div>
                ) : null}
                <label htmlFor="officePhoneNumber">טלפון במשרד</label>
                <Field
                  name="officePhoneNumber"
                  type="text"
                  placeholder="טלפון במשרד"
                  id="officePhoneNumber"
                />
                {errors.officePhoneNumber && touched.officePhoneNumber ? (
                  <div className="input-feedback">
                    {errors.officePhoneNumber}
                  </div>
                ) : null}
                <label htmlFor="passportNumber">מספר דרכון</label>
                <Field
                  name="passportNumber"
                  type="text"
                  placeholder="מספר דרכון"
                  id="passportNumber"
                />
                {errors.passportNumber && touched.passportNumber ? (
                  <div className="input-feedback">{errors.passportNumber}</div>
                ) : null}
                <label htmlFor="passportExpirationDate">
                  תאריך תפוגת דרכון
                </label>
                <Field
                  name="passportExpiration"
                  type="date"
                  placeholder="תוקף דרכון"
                  id="passportExpiration"
                />
                {errors.passportExpiration && touched.passportExpiration ? (
                  <div className="input-feedback">
                    {errors.passportExpiration}
                  </div>
                ) : null}
                <label htmlFor="email">אימייל</label>
                <Field
                  name="email"
                  type="email"
                  placeholder="אימייל"
                  id="email"
                />
                {errors.email && touched.email ? (
                  <div className="input-feedback">{errors.email}</div>
                ) : null}
                <label htmlFor="password">סיסמא</label>
                <Field
                  name="password"
                  type="password"
                  placeholder="סיסמא"
                  id="password"
                />
                {errors.password && touched.password ? (
                  <div className="input-feedback">{errors.password}</div>
                ) : null}
                <label htmlFor="passwordConfirmation">אימות סיסמא</label>
                <Field
                  name="password2"
                  type="password"
                  placeholder="אימות סיסמא"
                  id="password2"
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <div className="input-feedback">{errors.confirmPassword}</div>
                ) : null}
                <label htmlFor="role">בחר תפקיד:</label>
                <Field component="select" id="role" name="role">
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                  <option value="manager">Manager</option>
                </Field>
                {errors.roleList && touched.roleList ? (
                  <div className="input-feedback">{errors.roleList}</div>
                ) : null}
              </div>
            </div>
            <div className="buttons-container">
              <button
                type="submit"
                className="btn"
                // disabled={(errors, isSubmitting) => {
                //   checkDisabled(errors, isSubmitting);
                // }}
                disabled={!isValid || isSubmitting}
              >
                הוסף משתמש
              </button>
              {!isValid && (
                <div className="input-feedback">אנא מלא את כל השדות</div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default NewUser;

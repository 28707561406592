import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Login from './pages/Login';
import Main from './pages/user/Main';
import PrivateRoute from './components/PrivateRoute';
import MainAdmin from './pages/admin/MainAdmin';
import { useSelector } from 'react-redux';
import EditUser from './pages/admin/EditUser';
import SelectSeason from './pages/admin/SelectSeasonAdmin';
import Registration from './pages/user/Registration';
import ChooseCycle from './pages/user/ChooseCycle';
import ChoosePlan from './pages/user/ChoosePlan';
import NewUser from './pages/admin/NewUser';
import EditFamily from './pages/admin/EditFamily';
import EditTrips from './pages/admin/EditTripSection';
import TripsReg from './components/TripsReg';
import AddTrip from './pages/admin/AddTrip';
import EditCycle from './pages/admin/EditCycle';
import Admin from './pages/admin/Admin';
import Refund from './pages/admin/Refund';
import Voucher from './pages/admin/Voucher';
import Reports from './pages/admin/Reports';
import AddCycle from './pages/admin/AddCycle';
import Logs from './pages/admin/Logs';
import EditTrip from './pages/admin/EditTrip';

function App() {
  const isAdmin = useSelector(
    (state) =>
      state.auth.user?.roles?.includes('admin') ||
      JSON.parse(localStorage.getItem('user'))?.roles?.includes('admin')
  );

  if (!isAdmin) {
    return (
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/main" element={<PrivateRoute />}>
            <Route path="/main" element={<Main />} />
          </Route>
          <Route path="/registration" element={<PrivateRoute />}>
            <Route path="/registration" element={<Registration />} />
          </Route>
          <Route path="/trips" element={<PrivateRoute />}>
            <Route path="/trips" element={<TripsReg />} />
          </Route>
          <Route path="/ChooseCycle" element={<PrivateRoute />}>
            <Route path="/ChooseCycle" element={<ChooseCycle />} />
          </Route>
          <Route path="/ChoosePlan" element={<PrivateRoute />}>
            <Route path="/ChoosePlan" element={<ChoosePlan />} />
          </Route>
          <Route path="/user/:id" element={<PrivateRoute />}>
            <Route path="/user/:id" element={<EditUser />} />
          </Route>
          <Route path="/editFamily/:id" element={<PrivateRoute />}>
            <Route path="/editFamily/:id" element={<EditFamily />} />
          </Route>
        </Routes>
        <ToastContainer />
      </Router>
    );
  } else
    return (
      <>
        <Router>
          <div className="Container">
            <Header />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/main" element={<PrivateRoute />}>
                <Route path="/main" element={<MainAdmin />} />
              </Route>
              <Route path="/user/:id" element={<PrivateRoute />}>
                <Route path="/user/:id" element={<EditUser />} />
              </Route>
              <Route path="/seasons/:id" element={<PrivateRoute />}>
                <Route path="/seasons/:id" element={<SelectSeason />} />
              </Route>
              <Route path="/registration" element={<PrivateRoute />}>
                <Route path="/registration" element={<Registration />} />
              </Route>
              <Route path="/trips" element={<PrivateRoute />}>
                <Route path="/trips" element={<TripsReg />} />
              </Route>
              <Route path="/ChooseCycle" element={<PrivateRoute />}>
                <Route path="/ChooseCycle" element={<ChooseCycle />} />
              </Route>
              <Route path="/ChoosePlan" element={<PrivateRoute />}>
                <Route path="/ChoosePlan" element={<ChoosePlan />} />
              </Route>
              <Route path="/newUser" element={<PrivateRoute />}>
                <Route path="/newUser" element={<NewUser />} />
              </Route>
              <Route path="/editFamily/:id" element={<PrivateRoute />}>
                <Route path="/editFamily/:id" element={<EditFamily />} />
              </Route>
              <Route path="/editTrips/:season" element={<PrivateRoute />}>
                <Route path="/editTrips/:season" element={<EditTrips />} />
              </Route>
              <Route path="/addTrip" element={<PrivateRoute />}>
                <Route path="/addTrip" element={<AddTrip />} />
              </Route>
              <Route path="/editCycle" element={<PrivateRoute />}>
                <Route path="/editCycle" element={<EditCycle />} />
              </Route>
              <Route path="/admin" element={<PrivateRoute />}>
                <Route path="/admin" element={<Admin />} />
              </Route>
              <Route path="/refund" element={<PrivateRoute />}>
                <Route path="/refund" element={<Refund />} />
              </Route>
              <Route path="/voucher" element={<PrivateRoute />}>
                <Route path="/voucher" element={<Voucher />} />
              </Route>
              <Route path="/reports" element={<PrivateRoute />}>
                <Route path="/reports" element={<Reports />} />
              </Route>
              <Route path="/addCycle" element={<PrivateRoute />}>
                <Route path="/addCycle" element={<AddCycle />} />
              </Route>
              <Route path="/logs" element={<PrivateRoute />}>
                <Route path="/logs" element={<Logs />} />
              </Route>
              <Route path="/editTrip" element={<PrivateRoute />}>
                <Route path="/editTrip" element={<EditTrip />} />
              </Route>
            </Routes>
          </div>
        </Router>
        <ToastContainer />
      </>
    );
}

export default App;

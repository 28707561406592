import { useNavigate } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { addCycleSchema } from '../utils/validations/Schemas';
import CustomInput from './CustomInput';
import { clacDays } from '../utils/general/utils';
const CycleForm = ({ initialValues, Submit, onDelete, btnText }) => {
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addCycleSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        Submit(values);
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form>
          <div className="form-container">
            <div className="form-group">
              <CustomInput label="תאריך יציאה" type="date" name="departDate" />

              <CustomInput label="תאריך חזרה" type="date" name="returnDate" />
              <CustomInput
                label="מספר ימים"
                type="number"
                name="days"
                disabled={true}
                value={
                  values.returnDate && values.departDate
                    ? clacDays(values.returnDate, values.departDate)
                    : 0
                }
              />

              <CustomInput
                label="מספר לילות"
                type="number"
                name="nights"
                disabled={true}
                value={
                  values.returnDate && values.departDate
                    ? clacDays(values.returnDate, values.departDate) - 1
                    : 0
                }
              />

              <CustomInput label="הערות" type="text" name="notes" />

              <CustomInput
                label="מספר מקומות פנויים"
                type="number"
                name="availableSlots"
              />
            </div>
          </div>
          <div className="buttons-container">
            <button
              className={isSubmitting ? 'btn disabled' : 'btn'}
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              {btnText}
            </button>

            <button
              className="btn"
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              ביטול
            </button>
            {onDelete && (
              <button
                className="btn"
                type="button"
                onClick={() => {
                  onDelete();
                }}
              >
                הסר מחזור
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default CycleForm;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSeasons,
  organizationSelector,
  setSeason,
} from '../../features/organization/organizationSlice';
import Spinner from '../../components/Spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUser, makeWorkerReport } from '../../features/users/usersSlice';
import Modal from '../../components/Modal';

export function RenderSeasonsList({ seasons, handleSelectSeason }) {
  return (
    <ul className="list-group">
      {seasons.map((season) => (
        <li className="item seasonList" key={season}>
          <div onClick={() => handleSelectSeason(season)}>{season}</div>
        </li>
      ))}
    </ul>
  );
}

const SelectSeason = () => {
  let { user } = useSelector((state) => state.users);
  const [reports, setReports] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelectSeason = (season) => {
    console.log(season);
    // navigate to user registered trips per this season
    dispatch(setSeason(season));
    navigate(`/registration`);
  };

  const generateReport = () => {
    dispatch(makeWorkerReport())
      .unwrap()
      .then((res) => {
        setReports(res.data);
        setModalOpen(true);
      });
    console.log('generate report');
  };

  const goToEditWorker = () => {
    dispatch(getUser(user._id)).then(() => {
      navigate(`/user/${user._id}`);
    });
  };

  useEffect(() => {
    if (seasons.length === 0) {
      dispatch(getSeasons())
        .unwrap()
        .catch((err) => {
          toast.error(err.message);
        });
    }
  });
  const { seasons, isLoading } = useSelector(organizationSelector);
  return (
    <div className="container">
      <h2>בחר עונה</h2>
      {isLoading && <Spinner />}
      <RenderSeasonsList
        handleSelectSeason={handleSelectSeason}
        seasons={seasons}
      />
      <button className="btn btn-main" onClick={goToEditWorker}>
        עריכת פרטים אישיים
      </button>
      <button className="btn btn-main" onClick={generateReport}>
        דו״ח עובד
      </button>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <h3 className="heading">{`דו"ח עובד ${user.firstName} ${user.lastName}`}</h3>
        <ul className="list-group">
          {reports.map((report) => (
            <li className="" key={report._id}>
              {` ${report.year} - ${report.season} : ${report.tripName} `}
            </li>
          ))}
        </ul>
      </Modal>
    </div>
  );
};
export default SelectSeason;

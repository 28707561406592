import PropTypes from 'prop-types';

const InputManager = ({
  values,
  onSubmit,
  placeHolder,
  labelText,
  valuesManager,
  setValuesManager,
}) => {
  if (Array.isArray(values)) {
    return values.map((_, index) => {
      return (
        <div key={index} className="form-group">
          <form onSubmit={onSubmit}>
            <label htmlFor="">{labelText}</label>
            <input
              placeholder={placeHolder}
              type="text"
              value={'' || valuesManager[index]}
              onChange={(e) => {
                const newValues = [...values];
                newValues[index] = e.target.value;
                setValuesManager(newValues);
              }}
            />
          </form>
        </div>
      );
    });
  } else {
    return Array.from({ length: values - 1 }).map((_, index) => {
      return (
        <div key={index} className="form-group">
          <form onSubmit={onSubmit}>
            <label>{labelText}</label>
            <input
              placeholder={placeHolder}
              type="text"
              value={'' || valuesManager[index]}
              onChange={(e) => {
                const newValues = [...valuesManager];
                newValues[index] = e.target.value;
                setValuesManager(newValues);
              }}
            />
            <button type="submit" className="btn btn-main">
              הוסף
            </button>
          </form>
        </div>
      );
    });
  }
};
export default InputManager;

InputManager.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.number]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeHolder: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  valuesManager: PropTypes.array.isRequired,
  setValuesManager: PropTypes.func.isRequired,
};

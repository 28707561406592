import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CyclesLIst from '../../components/CyclesLIst';
import {
  archiveTrip,
  setCycle,
  setTrip,
} from '../../features/trips/tripsSlice';
import TripsList from '../user/TripsList';

function EditTrips() {
  const dispatch = useDispatch();
  const trip = useSelector((state) => state.trips.trip);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const navigate = useNavigate();

  const selectCycle = (cycle) => {
    dispatch(setCycle(cycle));
    navigate('/editCycle');
  };

  const handleAddTrip = () => {
    navigate('/addTrip');
  };

  const handleEditTrip = () => {
    navigate('/editTrip');
  };
  const handleDeleteTrip = () => {
    const ans = window.confirm('האם אתה בטוח שברצונך למחוק את הטיול?');
    if (ans) {
      //todo: delete trip
      alert('מחיקת טיול');
    }
  };
  const handleAddCycle = () => {
    navigate('/addCycle');
  };
  const handleArchiveTrip = () => {
    const ans = window.confirm('האם אתה בטוח שברצונך להעביר את הטיול לארכיון?');
    if (ans) {
      //todo: archive trip
      alert('העברת טיול לארכיון');

      dispatch(archiveTrip())
        .unwrap()
        .then(() => {
          toast.success('הטיול הועבר לארכיון בהצלחה');
          navigate(-1);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  const selectTrip = (trip) => {
    console.log(trip);
    setSelectedTrip(trip);
    dispatch(setTrip(trip));
  };
  if (!selectedTrip)
    return (
      <div className="container">
        <TripsList handleSelect={selectTrip} />
        <div className="buttons-container">
          <button className="btn btn-main" onClick={handleAddTrip}>
            הוסף טיול
          </button>
        </div>
      </div>
    );
  else
    return (
      <>
        <div className="container">
          <h2 className="title">{trip.tripName}</h2>

          <CyclesLIst handleSelect={selectCycle} showTitle={false} />
          <div className="buttons-container">
            <button className="btn btn-main" onClick={handleAddCycle}>
              הוסף מחזור
            </button>
            <button className="btn btn-main" onClick={handleEditTrip}>
              ערוך טיול
            </button>
            <button className="btn btn-main" onClick={handleDeleteTrip}>
              מחק טיול
            </button>
            <button className="btn btn-main" onClick={handleArchiveTrip}>
              העבר לארכיון
            </button>
          </div>
        </div>
      </>
    );
}
export default EditTrips;

import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getRegisteredUsersPerCycle } from '../features/trips/tripsSlice';
import Modal from './Modal';

const CyclesLIst = ({ handleSelect, showTitle = true }) => {
  const { trip } = useSelector((state) => state.trips);
  const { cycles } = trip;
  const [modalOpen, setModalOpen] = useState(false);
  const [CycleUsers, setCycleUsers] = useState([]);
  const dispatch = useDispatch();

  const generateReport = (cycle) => {
    console.log(cycle._id);

    dispatch(getRegisteredUsersPerCycle(cycle._id))
      .unwrap()
      .then((res) => {
        setCycleUsers(res.users);
        setModalOpen(true);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <>
      {showTitle && <h2 className="heading">{trip.tripName}</h2>}
      <div className="cyclesList">
        <div className="cycle-title">מחזור</div>
        <div className="cycle-title">תאריכים יציאה וחזרה</div>
        <div className="cycle-title">ימים</div>
        <div className="cycle-title">לילות</div>
        <div className="cycle-title">הערות</div>
        <div className="cycle-title">מקומות פנויים</div>
        <div></div>
      </div>
      {cycles &&
        cycles.map((cycle, index) => (
          <div
            className="cyclesList selectionItem"
            key={cycle._id}
            onClick={() => handleSelect(cycle)}
          >
            <div className="cycle-item"> {index + 1}</div>
            <div className="cycle-item">
              {`${new Date(cycle.departDate).toLocaleDateString(
                'he'
              )} - ${new Date(cycle.returnDate).toLocaleDateString('he')}`}
            </div>
            <div>{cycle.days}</div>
            <div>{cycle.nights}</div>
            <div>{cycle.notes}</div>

            <div>{cycle.availableSlots - cycle.numberOfSubscribers}</div>
            <button
              className="btn btn-main"
              onClick={(e) => {
                e.stopPropagation();
                generateReport(cycle);
              }}
            >
              {` דוח מחזור ${index + 1}`}
            </button>
          </div>
        ))}
      <Modal onClose={() => setModalOpen(false)} isOpen={modalOpen}>
        <h3 className="heading">דו"ח מחזור</h3>
        <div className="grid5">
          <div className="cycle-title">מספר עובד</div>
          <div className="cycle-title"> שם פרטי</div>
          <div className="cycle-title">שם משפחה</div>
          <div className="cycle-title">תאריכי מחזור</div>
          <div className="cycle-title">מספר נפשות</div>
          {CycleUsers.map((item, index) => (
            <Fragment key={index}>
              <div>{item.employeeId}</div>
              <div>{item.firstName}</div>
              <div>{item.lastName}</div>
              <div>{`${new Date(item.departDate)
                .toISOString()
                .slice(0, 10)} - ${new Date(item.returnDate)
                .toISOString()
                .slice(0, 10)}`}</div>
              <div>{item.roomType}</div>
            </Fragment>
          ))}
        </div>
      </Modal>
    </>
  );
};
export default CyclesLIst;

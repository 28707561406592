import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { extractErrorMessage } from '../../utils/general/utils';

import usersService from './usersService';

const initialState = {
  users: [],
  user: null,
  isError: false,
  isLoading: false,
  errorMessage: '',
  isSuccess: false,
  userFamily: [],
};

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (_, thunkAPI) => {
    try {
      const organization = thunkAPI.getState().auth.user.organization;
      return await usersService.getUsers(organization);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getUser = createAsyncThunk(
  'users/getUser',
  async (id, thunkAPI) => {
    try {
      return await usersService.getUser(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (user, thunkAPI) => {
    try {
      return await usersService.createUser(user);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (payload, thunkAPI) => {
    try {
      return await usersService.updateUser(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (id, thunkAPI) => {
    try {
      return await usersService.deleteUser(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async (_, thunkAPI) => {
    try {
      const email = thunkAPI.getState().users.user.email;
      return await usersService.restPassword(email);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const editUserFamily = createAsyncThunk(
  'users/editUserFamily',
  async (payload, thunkAPI) => {
    try {
      return await usersService.editUserFamily(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const whatTripsAmISignedUpFor = createAsyncThunk(
  'users/whatTripsAmISignedUpFor',
  async (_, thunkAPI) => {
    try {
      const id = thunkAPI.getState().users.user._id;
      return await usersService.whatTripsAmISignedUpFor(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const makeWorkerReport = createAsyncThunk(
  'users/makeWorkerReport',
  async (_, thunkAPI) => {
    try {
      const id = thunkAPI.getState().users.user._id;
      console.log('id', id);

      return await usersService.makeWorkerReport(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getBankAccount = createAsyncThunk(
  'users/getBankAccount',
  async (_, thunkAPI) => {
    try {
      const id = thunkAPI.getState().users.user._id;
      return await usersService.getBankAccount(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const updateBankAccount = createAsyncThunk(
  'users/updateBankAccount',
  async (payload, thunkAPI) => {
    try {
      const id = thunkAPI.getState().users.user._id;
      const payloadToSend = {
        bankDetails: payload,
        userId: id,
      };
      return await usersService.updateBankAccount(payloadToSend);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const addBankAccount = createAsyncThunk(
  'users/addBankAccount',
  async (payload, thunkAPI) => {
    try {
      const id = thunkAPI.getState().users.user._id;
      const payloadToSend = {
        bankDetails: payload,
        userId: id,
      };
      return await usersService.addBankAccount(payloadToSend);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);



export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: (state) => initialState,
    setUser: (state, action) => {
      state.user = action.payload;
    },
    removeUser: (state, action) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })

      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = action.payload;
        state.isLoading = false;
        state.users = [];
        state.user = null;
        state.isSuccess = false;
      })

      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })

      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })

      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.users.push(action.payload);
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })

      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })

      .addCase(updateUser.fulfilled, (state, action) => {
        const index = state.users.findIndex(
          (user) => user.id === action.payload.id
        );
        state.users[index] = action.payload;
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })

      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
    })

      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(getBankAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
      });
  },
});

export const { resetUsers, setUser, removeUser } = usersSlice.actions;

export default usersSlice.reducer;

import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { editFamilySchema } from '../../utils/validations/Schemas';
import { editUserFamily } from '../../features/users/usersSlice';
import { toast } from 'react-toastify';
const EditFamily = () => {
  const user = useSelector((state) => state.users.user);
  const { family } = user;
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(family[0]);

  const handleSelect = (e) => {
    const selectedFamily = family.find((fam) => fam._id === e.target.value);
    setSelected(selectedFamily);
  };

  return (
    <div>
      <h1 className="heading">Family</h1>
      <div className="container">
        <div className="form-group">
          <label htmlFor="family">בחר חבר משפחה</label>
          <select
            className="form-control"
            name="family"
            id="family"
            onChange={handleSelect}
          >
            <option disabled>בחר חבר משפחה</option>
            {family.map((fam) => {
              return (
                <option key={fam._id} value={fam._id}>
                  {fam.firstName} {fam.lastName}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <>
        <div className="container">
          <h2>עריכת פרטי חבר משפחה</h2>
          <Formik
            validationSchema={editFamilySchema}
            enableReinitialize={true}
            initialValues={{
              firstName: selected.firstName,
              lastName: selected.lastName,
              firstNameEng: selected.firstNameEng,
              lastNameEng: selected.lastNameEng,
              gender: selected.gender,
              passportExpiration: selected.passportExpiration,
              passportNumber: selected.passportNumber,
              type: selected.type,
              ssn: selected.ssn || '',
            }}
            onSubmit={(values) => {
              const payload = {
                userId: user._id,
                memberId: selected._id,
                firstName: values.firstName,
                lastName: values.lastName,
                firstNameEng: values.firstNameEng,
                lastNameEng: values.lastNameEng,
                gender: values.gender,
                passportExpiration: values.passportExpiration,
                passportNumber: values.passportNumber,
                type: values.type,
                ssn: values.ssn,
                birthDate: values.birthDate,
              };

              dispatch(editUserFamily(payload))
                .unwrap()
                .then(() => {
                  toast.success('החבר משפחה עודכן בהצלחה');
                })
                .catch((err) => {
                  toast.error(err.message);
                });
            }}
          >
            {({
              handleSubmit,
              values,
              touched,
              isSubmitting,
              isValid,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="firstName">שם פרטי</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={values.firstName}
                    handleBlur={handleBlur}
                  />
                  <label htmlFor="lastName">שם משפחה</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={values.lastName}
                    handleBlur={handleBlur}
                  />
                  <label htmlFor="firstNameEng">שם פרטי באנגלית</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="firstNameEng"
                    id="firstNameEng"
                    value={values.firstNameEng}
                    handleBlur={handleBlur}
                  />
                  <label htmlFor="lastNameEng">שם משפחה באנגלית</label>
                  <Field
                    className="form-control"
                    type="text"
                    name="lastNameEng"
                    id="lastNameEng"
                    value={values.lastNameEng}
                    handleBlur={handleBlur}
                  />
                  <label htmlFor="gender">מין</label>
                  <Field
                    as="select"
                    className="form-control"
                    id="gender"
                    value={values.gender}
                    handleBlur={handleBlur}
                  >
                    <option value="male">זכר</option>
                    <option value="female">נקבה</option>
                  </Field>

                  <label htmlFor="passportExpiration">תאריך תפוגת דרכון</label>
                  <Field
                    className="form-control"
                    type="date"
                    name="passportExpiration"
                    id="passportExpiration"
                    value={new Date(values.passportExpiration)
                      .toISOString()
                      .slice(0, 10)}
                    handleBlur={handleBlur}
                  />
                  <label htmlFor="passportNumber">מספר דרכון</label>
                  <Field
                    className="form-control"
                    type="number"
                    name="passportNumber"
                    id="passportNumber"
                    value={values.passportNumber}
                    handleBlur={handleBlur}
                  />

                  <label htmlFor="ssn">מספר תעודת זהות</label>
                  <Field
                    className="form-control"
                    type="number"
                    name="ssn"
                    id="ssn"
                    value={values.ssn}
                    handleBlur={handleBlur}
                  />

                  <label htmlFor="type">סוג נלווה</label>
                  <Field
                    as="select"
                    className="form-control"
                    id="type"
                    value={values.type}
                    handleBlur={handleBlur}
                  >
                    <option value="child">ילד</option>
                    <option value="partner">בן זוג</option>
                  </Field>
                </div>
                <button
                  type="submit"
                  className="btn"
                  disabled={!isValid || isSubmitting}
                >
                  שמור
                </button>
                {!isValid && (
                  <div className="input-feedback">אנא מלא את כל השדות</div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </>
    </div>
  );
};
export default EditFamily;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import RideAddressDialog from '../../components/AddRideAdressDialog';
import CustomAlert from '../../components/CustomAlert';
import InputManager from '../../components/InputManager';
import { registerToTrip, setPlan } from '../../features/trips/tripsSlice';
import { getUser } from '../../features/users/usersSlice';
import { removeDuplicatesByName } from '../../utils/general/utils';

const ChoosePlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [valuesManager, setValuesManager] = useState([]);
  const { trip, plan, cycle, isError, errorMessage } = useSelector(
    (state) => state.trips
  );
  const { user, users } = useSelector((state) => state.users);
  const [viewFamilySelection, setViewFamilySelection] = useState(false);
  const [showAlert, setShowAlert] = useState(null);
  const { plans } = trip;
  const [selectedMembers, setSelectedMembers] = useState([user]);
  const { family } = user;
  const { id } = user;
  const { user: authUser } = useSelector((state) => state.auth);

  const [managerNotes, setManagerNotes] = useState('');
  const [isRideDialogOpen, setIsRideDialogOpen] = useState(false);
  const [rideAddress, setRideAddress] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(true);

  const userFamily = removeDuplicatesByName(family);

  useEffect(() => {
    dispatch(getUser(user._id))
      .unwrap()
      .catch((err) => {
        toast.error(err.message);
      });
  }, [dispatch, viewFamilySelection, user._id]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [isError, errorMessage]);

  // const getRegistrationDetails = (type) => {
  //   if (type === 'summit') {
  //     const payload = {
  //       tripId: trip._id,
  //       planId: plan._id,
  //       members: selectedMembers,
  //       cycleId: cycle._id,
  //       userMemberId: user._id,
  //       managerNotes: managerNotes ?? '',
  //       rideAddress: rideAddress ?? '',
  //     };
  //     return payload;
  //   } else if (type === 'alert') {
  //     const registrationDetails = {
  //       trip: {
  //         name: trip.name,
  //         departDate: trip.departDate,
  //         returnDate: trip.returnDate,
  //         members: trip.members,
  //       }
  //     };
  //     return registrationDetails;
  //   }
  // };

  // const transformDetailsToAlertJsxMessage = (details) => {
  //   const {
  //     tripId,
  //     planId,
  //     cycleId,
  //     userId,
  //     selectedMembers,
  //     managerNotes,
  //     rideAddress,
  //   } = details;

  //   const tripName = trip.name;
  //   const planName = plan.name;
  //   const cycleName = cycle.name;
  //   const userName = user.name;
  //   const selectedMembersNames = selectedMembers.map(
  //     (member) => `${member.firstName} ${member.lastName}`
  //   );
  //   const managerNotesMessage = managerNotes;
  //   const rideAddressMessage = rideAddress;

  //   return (
  //     <div>
  //       <p>פרטי ההרשמה:</p>
  //       <p>שם הטיול: {tripName}</p>
  //       <p>שם התוכנית: {planName}</p>
  //       <p>שם המחזור: {cycleName}</p>
  //       <p>שם המשתמש: {userName}</p>
  //       <p>משתמשים שנבחרו: {selectedMembersNames}</p>
  //       <p>הערות מנהל: {managerNotesMessage}</p>
  //       <p>כתובת הסעה: {rideAddressMessage}</p>
  //     </div>
  //   );
  // };

  const handleSelect = (item) => {
    dispatch(setPlan(item));
    setViewFamilySelection(true);

    toast.success('נבחרה תוכנית בהצלחה');
  };

  const handleSelectMember = (member) => {
    console.log(member);
    const maxMembers = plan.room.numberOfGuests;
    if (selectedMembers?.includes(member)) {
      toast.error('המשתמש כבר נבחר');
    } else if (selectedMembers.length < maxMembers) {
      setSelectedMembers([...selectedMembers, member]);
    } else {
      toast.error('הגעת למספר המשתמשים המרבי');
    }
  };

  const handleRemoveMember = (member) => {
    console.log(member);
    if (member._id === user._id) {
      toast.error(' הסרת את עצמך ');
    }
    const newMembers = selectedMembers.filter((m) => m._id !== member._id);
    setSelectedMembers(newMembers);
  };

  const addMe = () => {
    if (selectedMembers?.includes(user)) {
      toast.error('המשתמש כבר נבחר');
    } else if (selectedMembers.length < plan.numberOfGuests) {
      setSelectedMembers([...selectedMembers, user]);
    } else {
      toast.error('הגעת למספר המשתמשים המרבי');
    }
  };

  const handleRideAddressSubmit = () => {
    if (rideAddress === '') {
      toast.error('נא להזין כתובת');
    } else {
      setIsRideDialogOpen(false);
    }

    handleConfirm();
  };


  const handleConfirm = () => {
    const payload = {
      tripId: trip._id,
      planId: plan._id,
      members: selectedMembers,
      cycleId: cycle._id,
      userMemberId: user._id,
      managerNotes: managerNotes ?? '',
      rideAddress: rideAddress ?? '',
    };
    console.log(payload);

    if (payload.rideAddress !== '') {
      dispatch(registerToTrip(payload))
        .unwrap()
        .then(() => {
          toast.success('הרשמתך התבצעה בהצלחה');
          navigate('/trips');
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedMembers.length > plan.numberOfGuests) {
      toast.error('הגעת למספר המשתמשים המרבי');
    } else if (selectedMembers.length < plan.numberOfGuests) {
      toast.error('לא בחרת את כל המשתמשים');
    } else {
      valuesManager.forEach((value) => {
        const foundUsers = users.filter((u) => u.employeeId === Number(value));
        if (foundUsers.length === 0) {
          toast.error('לא נמצא משתמש עם מספר עובד זה');
        } else {
          if (selectedMembers?.includes(...foundUsers)) {
            toast.error('המשתמש כבר נבחר');
          } else {
            setSelectedMembers([...selectedMembers, ...foundUsers]);
          }
        }
      });
    }
  };

  const selectPlan = (
    <>
      <h2 className="heading">{trip.tripName}</h2>
      <div className="row-container">
        <div className="cycle-title">מספר הנפשות</div>
        <div className="cycle-title">הערות</div>
        <div className="cycle-title">השתתפות</div>
      </div>

      {plans.map((plan, idx) => {
        if (
          plan.room.type !== 1 &&
          plan?.room?.numberOfGuests > userFamily?.length
        ) {
          return (
            <div key={idx} className="row-container">
              <h2>אין מספיק נלווים כדי להרשם לתוכנית זו </h2>
              <button
                className="btn"
                onClick={() => {
                  navigate('/trips');
                }}
              >
                חזור
              </button>
              {authUser.roles?.includes('admin') &&
                !user.roles?.includes('admin') && (
                  <button
                    className="btn"
                    onClick={() => navigate(`/user/${id}`)}
                  >
                    הוסף נלווים
                  </button>
                )}
            </div>
          );
        } else
          return (
            <div
              className="row-container selectionItem"
              key={plan._id}
              onClick={() => handleSelect(plan)}
            >
              <div className="cycle-item"> {plan.room?.name} </div>
              <div className="cycle-item">{plan.notes}</div>
              <div className="cycle-item">{plan.price}</div>
            </div>
          );
      })}
    </>
  );

  const selectFamily = (
    <>
      <h2 className="heading">בחר משפחה ונלווים</h2>
      <div className="container">
        <h3>
          {`יש לבחור ${plan?.room?.numberOfGuests} נפשות לתוכנית ${plan.room?.name}`}
        </h3>
        {plan?.room?.type === 1 && (
          <InputManager
            values={plan?.room?.numberOfGuests}
            labelText="מספר עובד"
            placeHolder="חפש לפי מספר עובד"
            onSubmit={handleSubmit}
            valuesManager={valuesManager}
            setValuesManager={setValuesManager}
          />
        )}
        {plan?.room?.type === 0 &&
          userFamily &&
          userFamily.map((member, index) => (
            <div
              className="selectionItem"
              key={member._id}
              onClick={() => handleSelectMember(member)}
            >
              <div className="cycle-item">
                {member.firstName} {member.lastName}
              </div>
            </div>
          ))}
      </div>
      <div className="container">
        <h3 className="heading">נבחרו</h3>
        {selectedMembers.length > 0 && <p>לחיצה על שם הנלווה תסיר אותו</p>}
        {selectedMembers.length > 0 &&
          selectedMembers.map((member, index) => (
            <div
              className="selectionItem"
              key={member._id}
              onClick={() => {
                //check the room type
                if (plan?.room?.type === 1) {
                  if (member._id.toString() !== user._id.toString()) {
                    handleRemoveMember(member);
                  }
                } else {
                  handleRemoveMember(member);
                }
              }}
            >
              <div className="cycle-item">
                {member.firstName} {member.lastName}
              </div>
              <span className="warning">
                {trip.international &&
                new Date(member.passportExpiration) < Date.now()
                  ? 'דרכון לא בתוקף'
                  : ''}
              </span>
            </div>
          ))}
      </div>
      <div className="container">
        {authUser.roles?.includes('admin') && (
          <button className="btn" onClick={() => addMe()}>
            הוסף אותי
          </button>
        )}

        {authUser.roles?.includes('admin') && (
          <div className="form-group">
            <label htmlFor="regNotes">הוסף הערת מנהל</label>
            <input
              id="regNotes"
              type="text"
              onChange={(e) => setManagerNotes(e.target.value)}
              value={managerNotes}
            />
          </div>
        )}

        <button
          className="btn btn-main"
          onClick={() => setViewFamilySelection(false)}
        >
          חזור
        </button>
        <button
          className="btn btn-main"
          onClick={() => setIsRideDialogOpen(true)}
          disabled={
            selectedMembers.length === plan.room?.numberOfGuests ? false : true
          }
        >
          אישור
        </button>
      </div>

      <CustomAlert
        show={showAlert}
        title={'שים לב!'}
        message={`${errorMessage} האם ברצונך להמשיך בהרשמה? לחיצה על אישור תחליף את ההרשמה הקיימת בהרשמה זו`}
        buttons={[
          {
            label: 'אישור',
            onClick: () => {
              const payload = {
                tripId: trip._id,
                planId: plan._id,
                members: selectedMembers,
                cycleId: cycle._id,
                userMemberId: user._id,
                override: true,
                managerNotes: managerNotes ?? '',
              };
              return dispatch(registerToTrip(payload));
            },
            className: 'btn btn-main',
          },
          {
            label: 'ביטול',
            onClick: () => setShowAlert(false),
            className: 'btn btn-main',
          },
        ]}
      />
      <RideAddressDialog
        text={rideAddress ? 'בדוק שכתובת ההסעה נכונה' : 'נא הזן כתובת להסעה'}
        open={isRideDialogOpen}
        handleClose={() => setIsRideDialogOpen(false)}
        handleSubmit={handleRideAddressSubmit}
        rideAddress={rideAddress}
        handleChange={(e) => setRideAddress(e.target.value)}
      />
      {/* <AlertDialog
        open={isAlertOpen}
        handleClose={() => setIsAlertOpen(false)}
        title={'שים לב!'}
        text={'test'}
      /> */}
    </>
  );

  return <>{viewFamilySelection ? selectFamily : selectPlan}</>;
};
export default ChoosePlan;

import * as Yup from 'yup';
export const registerSchema = Yup.object().shape({
  firstName: Yup.string().required('שדה חובה'),
  firstNameEng: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'שם פרטי באנגלית')
    .required('שדה חובה'),
  lastName: Yup.string().required('שדה חובה'),
  lastNameEng: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'שם משפחה באנגלית')
    .required('שדה חובה'),
  email: Yup.string().email('כתובת מייל לא תקינה').required('שדה חובה'),
  password: Yup.string()
    .min(6, 'סיסמה חייבת להכיל לפחות 6 תווים')
    .required('שדה חובה'),
  password2: Yup.string()
    .oneOf([Yup.ref('password'), null], 'סיסמאות לא תואמות')
    .required('שדה חובה'),
  phoneNumber: Yup.number().required('שדה חובה'),
  employeeId: Yup.number().required('שדה חובה'),
  birthDate: Yup.date().required('שדה חובה'),
  gender: Yup.string().required('שדה חובה'),
  street: Yup.string().required('שדה חובה'),
  city: Yup.string().required('שדה חובה'),
  homeNumber: Yup.number().required('שדה חובה'),
  neighborhood: Yup.string().required('שדה חובה'),
  cellPhoneNumber: Yup.number().required('שדה חובה'),
  officePhoneNumber: Yup.number().required('שדה חובה'),
  ssn: Yup.number().min(9).required('שדה חובה'),
  passportNumber: Yup.number().required('שדה חובה'),
  passportExpiration: Yup.date().required('שדה חובה'),
});
export const editUserSchema = Yup.object().shape({
  firstName: Yup.string().required('שדה חובה'),
  firstNameEng: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'שם פרטי באנגלית')
    .required('שדה חובה'),
  lastName: Yup.string().required('שדה חובה'),
  lastNameEng: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'שם משפחה באנגלית')
    .required('שדה חובה'),
  email: Yup.string().email('כתובת מייל לא תקינה').required('שדה חובה'),
  password: Yup.string()
    .min(6, 'סיסמה חייבת להכיל לפחות 6 תווים')
    .required('שדה חובה'),
  phoneNumber: Yup.number().required('שדה חובה'),
  employeeId: Yup.number().required('שדה חובה'),
  birthDate: Yup.date().required('שדה חובה'),
  gender: Yup.string().required('שדה חובה'),
  street: Yup.string().required('שדה חובה'),
  city: Yup.string().required('שדה חובה'),
  homeNumber: Yup.number().required('שדה חובה'),
  // neighborhood: Yup.string().required('שדה חובה'),
  cellPhoneNumber: Yup.number().required('שדה חובה'),
  officePhoneNumber: Yup.number().required('שדה חובה'),
  ssn: Yup.number().min(9).max(9).required('שדה חובה'),
  passportNumber: Yup.number().required('שדה חובה'),
  passportExpiration: Yup.date().required('שדה חובה'),
});

export const editFamilySchema = Yup.object().shape({
  firstName: Yup.string().required('שדה חובה'),
  lastName: Yup.string().required('שדה חובה'),
  lastNameEng: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'שם משפחה באנגלית')
    .required('שדה חובה'),
  firstNameEng: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'שם פרטי באנגלית')
    .required('שדה חובה'),
});

export const bankAccountSchema = Yup.object().shape({
  bankName: Yup.string().required('שדה חובה'),
  bankNumber: Yup.number().required('שדה חובה'),
  branchNumber: Yup.number().required('שדה חובה'),
  accountNumber: Yup.number().required('שדה חובה'),
});

export const addCycleSchema = Yup.object().shape({
  departDate: Yup.date().required('שדה חובה'),
  returnDate: Yup.date()
    .min(Yup.ref('departDate'), 'תאריך החזרה חייב להיות גדול מתאריך היציאה')
    .required('שדה חובה'),

  notes: Yup.string(),
  availableSlots: Yup.number()
    .min(1, 'חייב להיות לפחות מקום אחד')
    .required('שדה חובה'),
});

export const addTripSchema = Yup.object().shape({
  tripName: Yup.string().required('שדה חובה'),
  lastRegistrationDate: Yup.date().required('שדה חובה'),
  departDate: Yup.date().required('שדה חובה'),
  returnDate: Yup.date()
    .min(Yup.ref('departDate'), 'תאריך החזרה חייב להיות גדול מתאריך היציאה')
    .required('שדה חובה'),
  notes: Yup.string(),
  availableSlots: Yup.number()
    .min(1, 'חייב להיות לפחות מקום אחד')
    .required('שדה חובה'),
  hasRide: Yup.boolean(),
});

export const editTripSchema = Yup.object().shape({
  tripName: Yup.string().required('שדה חובה'),
  lastRegistrationDate: Yup.date().required('שדה חובה'),
});

import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clacDays } from '../utils/general/utils';
import { addTripSchema } from '../utils/validations/Schemas';
import CustomCheckbox from './CustomCheckBox';
import CustomInput from './CustomInput';
import CustomSelect from './CustomSelect';

const TripForm = ({ initialValues, submit, addPlan, removePlan, editMode }) => {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const { roomTypes } = useSelector((state) => state.trips);

  return (
    <Formik
      // validationSchema={addTripSchema}
      validateOnChange={true}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        console.log(values);

        submit(values)
          .then(() => {
            setSubmitting(false);
            resetForm();
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(err.message);
          });

        navigate(-1);
      }}
    >
      {({ values, isValid, setFieldValue, handleSubmit, isSubmitting }) => {
        return (
          <Form onSubmit={handleSubmit}>
            {page === 0 && (
              <div className="form-container">
                <div className="form-group">
                  <CustomInput label="שם הטיול" type="text" name="tripName" />
                  <CustomInput
                    label="תאריך אחרון להרשמה"
                    type="date"
                    name="lastRegistrationDate"
                  />

                  {!editMode && (
                    <>
                      <CustomInput
                        type="date"
                        label="תאריך יציאה"
                        name="departDate"
                        value={values.defaultCycle.departDate}
                        onChange={(e) => {
                          setFieldValue(
                            'defaultCycle.departDate',
                            e.target.value
                          );
                        }}
                      />

                      <CustomInput
                        label="תאריך חזרה"
                        type="date"
                        name="returnDate"
                        value={values.defaultCycle.returnDate}
                        onChange={(e) => {
                          setFieldValue(
                            'defaultCycle.returnDate',
                            e.target.value
                          );
                        }}
                      />
                      <CustomInput
                        disabled={true}
                        label="מספר ימים"
                        type="number"
                        name="days"
                        value={
                          values.defaultCycle.departDate &&
                          values.defaultCycle.returnDate
                            ? clacDays(
                                values.defaultCycle.departDate,
                                values.defaultCycle.returnDate
                              )
                            : 0
                        }
                      />

                      <CustomInput
                        disabled={true}
                        label="מספר לילות"
                        type="number"
                        name="nights"
                        value={
                          values.defaultCycle.departDate &&
                          values.defaultCycle.returnDate
                            ? clacDays(
                                values.defaultCycle.departDate,
                                values.defaultCycle.returnDate
                              ) - 1
                            : 0
                        }
                      />

                      <CustomInput
                        label="מספר מקומות פנויים"
                        type="number"
                        name="availableSlots"
                        value={values.defaultCycle.availableSlots || 0}
                        onChange={(e) => {
                          setFieldValue(
                            'defaultCycle.availableSlots',
                            e.target.value
                          );
                        }}
                      />
                    </>
                  )}

                  <CustomInput label="הערות" type="text" name="notes" />

                  <CustomCheckbox
                    text={'האם הטיול לחול?'}
                    name="international"
                    checked={values.international}
                    onChange={(e) => {
                      setFieldValue('international', e.target.checked);
                    }}
                  />
                </div>
              </div>
            )}

            {page === 1 && (
              <div className="form-container">
                <>
                  {values.plans.map((plan, index) => {
                    return (
                      <div key={index}>
                        <h1 className="heading">{`תוכנית ${index + 1}`}</h1>

                        <div className="form-group">
                          <label htmlFor="roomType">בחר הרכב חדר</label>
                          <Field
                            as="select"
                            name="roomType"
                            key={index}
                            onChange={(e) => {
                              const plans = [...values.plans];
                              plans[index].roomType = JSON.parse(
                                e.target.value
                              );
                              setFieldValue('plans', plans);

                              console.log(values);
                            }}
                            value={JSON.stringify(plan.roomType)}
                          >
                            {roomTypes.map((roomType) => {
                              return (
                                <option
                                  value={JSON.stringify(roomType)}
                                  key={roomType._id}
                                >
                                  {`${roomType.name} ב ${roomType.numberOfRooms} חדרים`}
                                </option>
                              );
                            })}
                          </Field>
                          <CustomInput
                            label="הערות"
                            type="text"
                            name={`plans.${index}.notes`}
                            value={plan.notes}
                          />
                          <CustomInput
                            label="מחיר"
                            type="number"
                            name={`plans.${index}.price`}
                            value={plan.price}
                          />
                          <CustomInput
                            label="מספר חדרים"
                            type="number"
                            name={`plans.${index}.numberOfRooms`}
                            value={plan.roomType?.numberOfRooms}
                          />
                          <CustomInput
                            label="סוג חדר"
                            type="text"
                            name={`plans.${index}.roomType`}
                            value={plan.roomType?.name}
                            disabled={true}
                          />
                          <CustomInput
                            type="number"
                            label="מספר אורחים"
                            name={`plans.${index}.numberOfGuests`}
                            value={plan.roomType?.numberOfGuests}
                            disabled={true}
                          />
                        </div>
                        <button
                          className="btn"
                          type="button"
                          disabled={values.plans.length === 1}
                          onClick={() =>
                            setFieldValue(
                              values.plans,
                              removePlan(values.plans, index)
                            )
                          }
                        >
                          {`מחק תוכנית ${index + 1}`}
                        </button>
                      </div>
                    );
                  })}
                  <div className="buttons-container">
                    <button
                      className="btn btn-main"
                      type="button"
                      onClick={() => setPage((prev) => prev - 1)}
                    >
                      חזור
                    </button>
                    <button
                      className="btn btn-main"
                      type="button"
                      onClick={() =>
                        setFieldValue(
                          'plans',
                          addPlan(values.plans, values.defaultCycle)
                        )
                      }
                    >
                      הוסף הרכב נוסף
                    </button>
                  </div>
                </>
              </div>
            )}
            <div className="buttons-container">
              {page === 0 ? (
                <div
                  className="btn btn-main"
                  onClick={() => setPage((prev) => prev + 1)}
                >
                  הבא
                </div>
              ) : (
                <>
                  <button
                    type="submit"
                    className={
                      isSubmitting ? 'btn btn-main disabled' : 'btn btn-main'
                    }
                    disabled={!isValid || isSubmitting}
                  >
                    שלח
                  </button>

                  {!isValid && (
                    <div className="input-feedback">אנא מלא את כל השדות</div>
                  )}
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default TripForm;

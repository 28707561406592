import { toast } from 'react-toastify';
import { setCycle } from '../../features/trips/tripsSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CyclesLIst from '../../components/CyclesLIst';

const ChooseCycle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectCycle = (cycle) => {
    dispatch(setCycle(cycle));
    toast.success('נבחר מחזור בהצלחה');
    navigate('/ChoosePlan');
  };

  return <CyclesLIst handleSelect={selectCycle} />;
};
export default ChooseCycle;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import { createTrip, getRoomTypes } from '../../features/trips/tripsSlice';
import TripForm from '../../components/TripForm';

const AddTrip = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { season } = useSelector((state) => state.organization);
  const { isLoading, roomTypes } = useSelector((state) => state.trips);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!roomTypes.length) dispatch(getRoomTypes());
  }, [roomTypes, dispatch]);
  //todo add formik validation

  const addPlan = (arr) => {
    console.log(arr);
    arr.push({
      notes: '',
      price: '',
      numberOfRooms: 1,
      roomType: '',
      isActive: true,
      numberOfGuests: '',
    });
    return arr;
  };
  const removePlan = (arr, index) => {
    console.log(arr, index);
    arr.splice(index, 1);
    return arr;
  };
  if (isLoading) return <Spinner />;

  const initialValues = {
    tripName: '',
    isActive: true,
    season: season,
    archived: false,
    year: new Date().getFullYear(),
    international: false,
    lastRegistrationDate: '',
    defaultCycle: {
      departDate: '',
      returnDate: '',
      days: 4,
      nights: 3,
      availableSlots: 50,
      notes: '',
      organization: user.organization,
    },
    plans: [
      {
        notes: '',
        price: '',
        roomType: roomTypes[0],
        isActive: true,
        organization: user.organization,
      },
    ],
  };

  const submit = (values) => {
    const payload = {
      plans: values.plans,
      trip: {
        tripName: values.tripName,
        isActive: values.isActive,
        season: values.season,
        archived: values.archived,
        year: values.year,
        lastRegistrationDate: values.lastRegistrationDate,
        organization: user.organization,
      },
      cycle: values.defaultCycle,
    };
    dispatch(createTrip(payload))
      .unwrap()
      .then(() => {
        toast.success('Trip created successfully');
      });
  };

  return (
    <>
      <TripForm
        initialValues={initialValues}
        addPlan={addPlan}
        removePlan={removePlan}
        submit={submit}
      />
    </>
  );
};
export default AddTrip;

import api from '../../App/api';
const prefix = '/users';

const getUsers = async (organization) => {
  const response = await api.get(`${prefix}/all/${organization}`);
  return response.data;
};

const getUser = async (id) => {
  const response = await api.get(`${prefix}/${id}`);

  return response.data;
};

const createUser = async (user) => {
  const response = await api.post(`${prefix}/new`, user);
  return response.data;
};

const updateUser = async (payload) => {
  const response = await api.put(`${prefix}/edit`, payload);
  return response.data;
};

const restPassword = async (email) => {
  return await api.post(`${prefix}/password`, email);
};

const editUserFamily = async (payload) => {
  return await api.put(`${prefix}/edit/family`, payload);
};

const deleteUser = async (id) => {
  return await api.delete(`${prefix}/delete`, {
    data: {
      id,
    },
  });
};

const resetPassword = async (email) => {
  const response = await api.post(`/forgotPassword`, email);
  return response.data;
};

const whatTripsAmISignedUpFor = async (id) => {
  const response = await api.post(`${prefix}/whatTripsAmISignedUpFor`, {
    userId: id,
  });
  return response.data;
};

const makeWorkerReport = async (userId) => {
  const response = await api.post(`${prefix}/makeWorkerReport`, {
    id: userId,
  });
  return response.data;
};

const getBankAccount = async (id) => {
  const response = await api.get(`${prefix}/bankAccount/${id}`);
  return response.data;
};

const updateBankAccount = async (payload) => {
  const response = await api.put(`${prefix}/bankAccount`, payload);
  return response.data;
};

const addBankAccount = async (payload) => {
  const response = await api.post(`${prefix}/bankAccount`, payload);
  return response.data;
};


const usersService = {
  getUsers,
  getUser,
  createUser,
  updateUser,
  restPassword,
  editUserFamily,
  deleteUser,
  resetPassword,
  whatTripsAmISignedUpFor,
  makeWorkerReport,
  getBankAccount,
  updateBankAccount,
  addBankAccount,
};

export default usersService;

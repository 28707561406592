import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { extractErrorMessage } from '../../utils/general/utils';

import tripsService from './tripService';

const initialState = {
  trips: [],
  trip: {},
  isError: false,
  isLoading: false,
  errorMessage: '',
  isSuccess: false,
  cycle: {},
  plan: {},
  family: [],
  roomTypes: [],
};

export const getTrips = createAsyncThunk(
  'trips/getTrips',
  async (_, thunkAPI) => {
    try {
      const season = thunkAPI.getState().organization.season;
      return await tripsService.getTrips(season);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getTrip = createAsyncThunk(
  'trips/getTrip',
  async (_, thunkAPI) => {
    try {
      const trip = thunkAPI.getState().trips.trip;
      const tripId = trip._id;
      return await tripsService.getTrip(tripId);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const createTrip = createAsyncThunk(
  'trips/createTrip',
  async (payload, thunkAPI) => {
    try {
      return await tripsService.createTrip(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const updateTrip = createAsyncThunk(
  'trips/updateTrip',
  async (trip, thunkAPI) => {
    try {
      return await tripsService.updateTrip(trip);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const registerToTrip = createAsyncThunk(
  'trips/registerToTrip',
  async (payloadData, thunkAPI) => {
    try {
      return await tripsService.registerToTrip(payloadData);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const editCycle = createAsyncThunk(
  'trips/editCycle',
  async (payloadData, thunkAPI) => {
    try {
      const cycleId = thunkAPI.getState().trips.cycle._id;
      return await tripsService.editCycle(payloadData, cycleId);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const deleteCycle = createAsyncThunk(
  'trips/deleteCycle',
  async (_, thunkAPI) => {
    try {
      const cycleId = thunkAPI.getState().trips.cycle._id;
      return await tripsService.deleteCycle(cycleId);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getRoomTypes = createAsyncThunk(
  'trips/getRoomTypes',
  async (_, thunkAPI) => {
    try {
      return await tripsService.getRoomTypes();
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const signUpForVoucher = createAsyncThunk(
  'trips/signUpForVoucher',
  async (voucherOption, thunkAPI) => {
    try {
      const season = thunkAPI.getState().organization.season;
      return await tripsService.signUpForVoucher(voucherOption, season);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getRegisteredUsersPerTrip = createAsyncThunk(
  'trips/getRegisteredUsersPerTrip',
  async (_, thunkAPI) => {
    try {
      const tripId = thunkAPI.getState().trips.trip._id;
      const season = thunkAPI.getState().organization.season;
      const cycleId = thunkAPI.getState().trips.cycle._id;
      const year =
        thunkAPI.getState().trips.trip.year || new Date().getFullYear();

      return await tripsService.getRegisteredUsersPerTrip(
        tripId,
        season,
        cycleId,
        year
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const generateTripReport = createAsyncThunk(
  'trips/generateTripReport',
  async (_, thunkAPI) => {
    try {
      const tripId = thunkAPI.getState().trips.trip._id;
      const season = thunkAPI.getState().organization.season;
      const cycleId = thunkAPI.getState().trips.cycle._id;
      const year =
        thunkAPI.getState().trips.trip.year || new Date().getFullYear();

      const payload = {
        tripId,
        season,
        cycleId,
        year,
      };

      return await tripsService.generateTripReport(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const checkIfTripHasSignup = createAsyncThunk(
  'trips/checkIfTripHasSignup',
  async (_, thunkAPI) => {
    try {
      const tripId = thunkAPI.getState().trips.trip._id;
      return await tripsService.checkIfTripHasSignup(tripId);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getRegisteredUsersPerCycle = createAsyncThunk(
  'trips/getRegisteredUsersPerCycle',
  async (cycleId, thunkAPI) => {
    try {
      return await tripsService.getRegisteredUsersPerCycle(cycleId);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const registerUserForRefund = createAsyncThunk(
  'trips/registerUserForRefund',
  async (_, thunkAPI) => {
    try {
      const userId = thunkAPI.getState().users.user._id;
      const season = thunkAPI.getState().organization.season;
      const payload = {
        userId,
        season,
      };

      return await tripsService.registerUserForRefund(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const archiveTrip = createAsyncThunk(
  'trips/archiveTrip',
  async (_, thunkAPI) => {
    try {
      const tripId = thunkAPI.getState().trips.trip._id;
      return await tripsService.archiveTrip(tripId);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

const tripsSlice = createSlice({
  name: 'trips',
  initialState,
  reducers: {
    resetTrips: (state) => initialState,
    setTrip: (state, action) => {
      state.trip = action.payload;
    },
    removeTrip: (state, action) => {
      state.trip = {};
    },
    setCycle: (state, action) => {
      state.cycle = action.payload;
    },
    setPlan: (state, action) => {
      console.log('setPlan', action.payload);
      state.plan = action.payload;
    },
    setFamily: (state, action) => {
      state.family = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrips.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(getTrips.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.trips = action.payload.trips;
      })
      .addCase(getTrips.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      .addCase(getTrip.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(getTrip.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.trip = action.payload.trip;
      })
      .addCase(getTrip.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      .addCase(createTrip.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(createTrip.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(createTrip.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(updateTrip.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(updateTrip.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.trip = action.payload;
      })
      .addCase(updateTrip.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      .addCase(registerToTrip.pending, (state) => {
        console.log('registerToTrip pending');
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(registerToTrip.fulfilled, (state, action) => {
        // console.log('registerToTrip.fulfilled', action.payload);
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(registerToTrip.rejected, (state, action) => {
        console.log('registerToTrip rejected', action.payload);
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      .addCase(editCycle.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(editCycle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(editCycle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(deleteCycle.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(deleteCycle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(deleteCycle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(getRoomTypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(getRoomTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.roomTypes = action.payload.roomTypes;
      })
      .addCase(getRoomTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
      })
      .addCase(signUpForVoucher.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(signUpForVoucher.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(signUpForVoucher.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(getRegisteredUsersPerTrip.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.registeredUsersPerTrip = action.payload.registeredUsersPerTrip;
      })
      .addCase(getRegisteredUsersPerTrip.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(getRegisteredUsersPerTrip.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(generateTripReport.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(generateTripReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.fileName = action.payload.fileName;
        state.path = action.payload.path;
      })
      .addCase(generateTripReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload.message;
      });
  },
});

export const { resetTrips, setTrip, setCycle, setPlan, setFamily } =
  tripsSlice.actions;

export default tripsSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { extractErrorMessage } from '../../utils/general/utils';

import organizationService from './organizationService';

const initialState = {
  reports: [],
  report: {},
  seasons: [],
  season: {},
  isError: false,
  isLoading: false,
  errorMessage: '',
  isSuccess: false,
  regOptions: [],
  voucherAmount: 0,
  vouchersList: [],
};

export const createSeason = createAsyncThunk(
  'organization/createSeason',
  async (season, thunkAPI) => {
    try {
      const organization = thunkAPI.getState().auth.user.organization;
      return await organizationService.createSeason(season, organization);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getSeasons = createAsyncThunk(
  'organization/getSeasons',
  async (_, thunkAPI) => {
    try {
      const organization = thunkAPI.getState().auth.user.organization;
      return await organizationService.getSeasons(organization);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getReportsList = createAsyncThunk(
  'organization/getReportsList',
  async (_, thunkAPI) => {
    try {
      const organization = thunkAPI.getState().auth.user.organization;
      return await organizationService.getReportsList(organization);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getReport = createAsyncThunk(
  'organization/getReport',
  async (reportId, thunkAPI) => {
    try {
      const organization = thunkAPI.getState().auth.user.organization;
      return await organizationService.getReport(reportId, organization);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addSeason = createAsyncThunk(
  'organization/addSeason',
  async (season, thunkAPI) => {
    try {
      const organization = thunkAPI.getState().auth.user.organization;
      return await organizationService.addSeason(season, organization);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const deleteSeason = createAsyncThunk(
  'organization/deleteSeason',
  async (season, thunkAPI) => {
    try {
      const organization = thunkAPI.getState().auth.user.organization;
      return await organizationService.deleteSeason(season, organization);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getRegOptions = createAsyncThunk(
  'organization/getRegOptions',
  async (_, thunkAPI) => {
    console.log('in getRegOptions');
    try {
      return await organizationService.getRegOptions();
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const changeRegOptions = createAsyncThunk(
  'organization/changeRegOptions',
  async (options, thunkAPI) => {
    try {
      return await organizationService.changeRegOptions(options);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getVoucherAmount = createAsyncThunk(
  'organization/getVoucherAmount',
  async (id, thunkAPI) => {
    try {
      return await organizationService.getVoucherAmount(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getVouchersList = createAsyncThunk(
  'organization/getVouchersList',
  async (id, thunkAPI) => {
    try {
      return await organizationService.getVouchersList(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getVoucherEligibilities = createAsyncThunk(
  'organization/getVoucherEligibilities',
  async (_, thunkAPI) => {
    try {
      return await organizationService.getVoucherEligibilities();
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const changeVoucherEligibilityPrice = createAsyncThunk(
  'organization/changeVoucherEligibilityPrice',
  async (id, price, thunkAPI) => {
    try {
      return await organizationService.changeVoucherEligibilityPrice(id, price);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const getRideSetting = createAsyncThunk(
  'organization/getRideSetting',
  async (_, thunkAPI) => {
    try {
      return await organizationService.getRideSetting();
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const updateRideSetting = createAsyncThunk(
  'organization/updateRideSetting',
  async (payload, thunkAPI) => {
    try {
      return await organizationService.updateRideSetting(payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    reset: (state) => initialState,
    setSeason: (state, action) => {
      state.season = action.payload;
    },
    selectReport: (state, action) => {
      state.report = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSeason.fulfilled, (state, action) => {
        state.seasons.push(action.payload);
      })
      .addCase(getSeasons.fulfilled, (state, action) => {
        state.seasons = action.payload;
      })
      .addCase(getReportsList.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = action.payload;
        state.isLoading = false;
        state.reports = [];
        state.report = {};
        state.isSuccess = false;
      })
      .addCase(getReportsList.fulfilled, (state, action) => {
        state.reports = action.payload;
      })
      .addCase(getReport.fulfilled, (state, action) => {
        state.report = action.payload;
      })
      .addCase(addSeason.fulfilled, (state, action) => {
        state.seasons.push(action.payload.season);
      })
      .addCase(addSeason.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      })
      .addCase(deleteSeason.fulfilled, (state, action) => {
        state.seasons = state.seasons.filter(
          (season) => season !== action.payload.season
        );
      })
      .addCase(deleteSeason.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      })
      .addCase(getRegOptions.fulfilled, (state, action) => {
        console.log('action.payload', action.payload);
        state.regOptions = action.payload.data;
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(getRegOptions.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      })
      .addCase(getRegOptions.pending, (state, action) => {
        state.isError = false;
        state.errorMessage = '';
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(changeRegOptions.fulfilled, (state, action) => {
        state.regOptions = action.payload.data;
        state.isSuccess = true;
        state.isError = false;
        state.errorMessage = '';
        state.isLoading = false;
      })
      .addCase(changeRegOptions.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = action.payload.message;
        state.isLoading = false;
        state.isSuccess = false;
      })
      .addCase(getVoucherAmount.fulfilled, (state, action) => {
        console.log('action.payload get vouch', action.payload);
        state.voucherAmount = action.payload.data;
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(getVoucherAmount.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      })
      .addCase(getVoucherAmount.pending, (state, action) => {
        state.isError = false;
        state.errorMessage = '';
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getVouchersList.fulfilled, (state, action) => {
        state.vouchersList = action.payload.data;
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(getVouchersList.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = action.payload.message;
        state.isLoading = false;
        state.isSuccess = false;
      })
      .addCase(getVouchersList.pending, (state, action) => {
        state.isError = false;
        state.errorMessage = '';
        state.isLoading = true;
        state.isSuccess = false;
      });
  },
});

export const { reset, selectReport, setSeason } = organizationSlice.actions;
export const organizationSelector = (state) => state.organization;
export default organizationSlice.reducer;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import {
  getRegOptions,
  organizationSelector,
} from '../../features/organization/organizationSlice';
import { whatTripsAmISignedUpFor } from '../../features/users/usersSlice';

const Registration = () => {
  const { season, regOptions, isLoading } = useSelector(organizationSelector);
  const { user } = useSelector((state) => state.users);
  const year = new Date().getFullYear();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!regOptions.length) {
      dispatch(getRegOptions());
    }
  }, [dispatch, regOptions]);

  useEffect(() => {
    dispatch(whatTripsAmISignedUpFor())
      .unwrap()
      .then((res) => {
        toast.success(res.result);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [user, season]);

  const handleSelect = (element) => {
    const { name, nameEng } = element;
    navigate(`/${nameEng}`);
    console.log('you selected', name);
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <h3 className="heading">{`${season} ${year}`}</h3>
      <div className="row-container">
        {user.isRetired && (
          <div className="container">
            <h3>הנך צריך להוסיף 4400 ש"ח כדי להירשם לטיול</h3>
            <button className="btn btn-main" onClick={() => navigate('/trips')}>
              טיול
            </button>
          </div>
        )}

        {!user.isRetired &&
          regOptions.length &&
          regOptions.map((element) => {
            if (element.isActive) {
              return (
                <div key={element._id}>
                  <button
                    onClick={() => handleSelect(element)}
                    className="btn btn-main"
                  >
                    {element.name}
                  </button>
                </div>
              );
            }
            return null;
          })}
      </div>
    </>
  );
};
export default Registration;

import { useField } from 'formik';
import React from 'react';
//this is a custom checkbox component that uses the useField hook from formik
const CustomCheckbox = ({ text, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-group">
      <input
        type={'checkbox'}
        {...field}
        {...props}
        className={meta.touched && meta.error ? 'input-error' : ''}
      />
      <span>{text}</span>
      {meta.touched && meta.error && (
        <div className="input-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default CustomCheckbox;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getSeasons,
  organizationSelector,
  addSeason,
  deleteSeason,
  setSeason,
} from '../features/organization/organizationSlice';
import Spinner from './Spinner';

const EditTripsSection = () => {
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [newSeason, setNewSeason] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelectSeason = (season) => {
    console.log(season);
    dispatch(setSeason(season));
    navigate(`/editTrips/${season}`);
  };

  const handleAddSeason = () => {
    console.log(newSeason);
    if (newSeason.length > 0) {
      dispatch(addSeason(newSeason))
        .then(() => toast.success('Season added successfully'))
        .catch((err) => toast.error(err));
      setNewSeason('');
      setAdding(false);
    }
  };

  const handleDeleteSeason = (season) => {
    dispatch(deleteSeason(season)).catch((error) => {
      toast.error(error);
    });
  };

  useEffect(() => {
    if (seasons.length === 0) {
      dispatch(getSeasons())
        .unwrap()
        .catch((err) => {
          toast.error(err.message);
        });
    }
  });

  const { seasons, isLoading } = useSelector(organizationSelector);
  return (
    <div className="container">
      <h2>עריכת טיולים</h2>
      {isLoading && <Spinner />}
      <ul className="list-group">
        {seasons.map((season, index) => (
          <div key={season + index}>
            <li>
              <div className="item seasonList" onClick={() => handleSelectSeason(season)}>
                {season}
              </div>
            </li>
            {editing && (
              <button
                className="btn btn-main deleteSeason"
                onClick={() => handleDeleteSeason(season)}
              >{`מחק את ${season}`}</button>
            )}
          </div>
        ))}
      </ul>

      {adding && (
        <div className="form-group">
          <label htmlFor="season">הוסף עונה</label>
          <input
            type="text"
            value={newSeason}
            onChange={(e) => setNewSeason(e.target.value)}
          />
          <button className="btn btn-main addSeason" onClick={handleAddSeason}>
            הוסף
          </button>
        </div>
      )}

      <button onClick={() => setAdding(!adding)} className="btn btn-main">
        {adding ? 'ביטול הוספה' : 'הוסף עונה'}
      </button>
      <button onClick={() => setEditing(!editing)} className="btn btn-main">
        {editing ? 'ביטול מחיקת עונה' : 'מחיקת עונה'}
      </button>
    </div>
  );
};
export default EditTripsSection;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { extractErrorMessage } from '../../utils/general/utils';
import reportService from './reportsService';

const initialState = {
  reports: [],
  report: {},
  isError: false,
  isLoading: false,
  errorMessage: '',
  isSuccess: false,
};

export const downloadReport = createAsyncThunk(
  'report/downloadReport',
  async (_, thunkAPI) => {
    try {
      const path = thunkAPI.getState().trips.path;
      const fileName = thunkAPI.getState().trips.fileName;
      return await reportService.downloadReport(path, fileName);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadReport.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(downloadReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.report = action.payload;
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default reportSlice.reducer;

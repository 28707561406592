import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReportsList,
  organizationSelector,
  selectReport,
} from '../features/organization/organizationSlice';
import Spinner from '../components/Spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function RenderReportsList(props) {
  return (
    <ul>
      {props.reports.map((report) => (
        <li
          className="item reportsItem"
          key={report}
          onClick={() => {
            props.onSelectReport(report);
          }}
        >
          <div>{report}</div>
        </li>
      ))}
    </ul>
  );
}

const ReportsList = () => {
  const dispatch = useDispatch();
  const { reports, isLoading } = useSelector(organizationSelector);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getReportsList())
      .unwrap()
      .catch((err) => {
        toast.error(err.message);
      });
  }, [dispatch]);

  const onSelectReport = (report) => {
    dispatch(selectReport(report));
    navigate(`/reports`);
  };

  return (
    <>
      <h2>דוחות</h2>
      {isLoading && <Spinner />}

      <RenderReportsList
        reports={reports}
        onSelectReport={onSelectReport}
      />
    </>
  );
};
export default ReportsList;

import ReportsList from '../../components/ReportsList';
import UsersList from '../../components/UsersList';
import EditTrips from '../../components/EditSeasons';

import { useNavigate } from 'react-router-dom';
import { setUser } from '../../features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import EditTripsSection from '../../components/EditSeasons';

function MainAdmin() {
  const navigate = useNavigate();
  const { users } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  const goToArchive = () => {
    navigate('/archive');
  };

  const archiveAll = () => {
    console.log('archive all');
  };

  const addNewWorker = () => {
    console.log('add new worker');
    navigate('/newUser');
  };

  const handleSelect = async (id) => {
    const userFound = users.find((user) => user._id === id);
    console.log(userFound);
    if (userFound) {
      dispatch(setUser(userFound));
    }
    const navigateToUser = (user) => {
      navigate(`/seasons/${user._id}`);
    };

    navigateToUser(userFound);
  };

  const goToAdminPanel = () => {
    navigate('/admin');
  };

  return (
    <>
      <div className="grid-container">
        <div className="desktopOnly"></div>
        <div>
          <ReportsList />
        </div>
        <div>
          <UsersList handleSelect={handleSelect} />
        </div>
        <div>
          <EditTripsSection />
        </div>
        <div className="desktopOnly"></div>
        <div className="desktopOnly"></div>
        <div>
          <button className="btn btn-main" onClick={goToArchive}>
            ארכיון
          </button>
          <button className="btn btn-main" onClick={archiveAll}>
            העבר הכל לארכיון
          </button>
          <button className="btn btn-main" onClick={addNewWorker}>
            עובד חדש
          </button>
          <button className="btn btn-main" onClick={goToAdminPanel}>
            ניהול מערכת
          </button>
        </div>
      </div>
    </>
  );
}
export default MainAdmin;

import axios from 'axios';

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:8000';
} else {
  axios.defaults.baseURL = 'https://trips-server.wegain.co.il';
}

const api = axios.create({
  baseURL: axios.defaults.baseURL,

  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      console.log('refreshing token');

      const newToken = await axios.post(`${axios.defaults.baseURL}/refresh`, {
        expiredToken: localStorage.getItem('token'),
      });

      //get new token from response and save it to local storage and to the header of the request that caused the error
      localStorage.setItem('token', newToken.data.accessToken);
      error.config.headers.Authorization = `Bearer ${newToken.data.accessToken}`;

      //retry the request that caused the error
      return api(error.config);
    }

    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.data.accessToken) {
      localStorage.setItem('token', response.data.accessToken);
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;

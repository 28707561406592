import { Field, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import {
  checkIfTripHasSignup,
  getRoomTypes,
} from '../../features/trips/tripsSlice';
import { editTripSchema } from '../../utils/validations/Schemas';
import CustomInput from '../../components/CustomInput';
import CustomCheckBox from '../../components/CustomCheckBox';

const EditTrip = () => {
  const { trip, isLoading, roomTypes } = useSelector((state) => state.trips);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoomTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkIfTripHasSignup())
      .unwrap()
      .then((result) => {
        result ?? toast.warning(result.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [trip, dispatch]);

  const removePlan = (arr, index) => {
    console.log(arr, index);
    arr.splice(index, 1);
    return arr;
  };

  const initialValues = {
    tripName: trip.tripName,
    international: trip.international ?? false,
    lastSignupDate: trip.lastSignupDate
      ? new Date(trip.lastSignupDate).toISOString().split('T')[0]
      : '',
    plans: [...trip.plans],
  };

  const submit = (values) => {
    console.log(values);

    const payload = {
      tripName: values.tripName,
      international: values.international,
      lastSignupDate: values.lastSignupDate,
      plans: values.plans,
    };

    //todo: update trip and plans
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={editTripSchema}
    >
      {({
        values,
        isValid,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        handleBlur,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className="form-container">
              <div className="form-group">
                <CustomInput name="tripName" label="שם הטיול" />
                <CustomCheckBox name="international" text="טיול בינלאומי" />
                <CustomInput
                  name="lastSignupDate"
                  label="מועד אחרון להרשמה"
                  type="date"
                />
                {values.plans.map((plan, idx) => {
                  return (
                    <div key={idx}>
                      <h5 htmlFor="">תוכנית {idx + 1}</h5>

                      <label htmlFor="">הערות</label>
                      <Field name={`plans[${idx}].notes`} />
                      <label htmlFor="">מחיר</label>
                      <Field name={`plans[${idx}].price`} />

                      <label htmlFor="">סוג חדר</label>
                      <Field
                        as="select"
                        name={`plans[${idx}].room.name`}
                        id={`plans[${idx}].room.name`}
                        onChange={(e) => {
                          setFieldValue(
                            `plans[${idx}].room.name`,
                            e.target.value
                          );
                          setFieldValue(
                            `plans[${idx}].room.numberOfGuests`,
                            `${
                              roomTypes.find(
                                (roomType) => roomType.name === e.target.value
                              ).numberOfGuests
                            }`
                          );
                          setFieldValue(
                            `plans[${idx}].room.numberOfRooms`,
                            `${
                              roomTypes.find(
                                (roomType) => roomType.name === e.target.value
                              ).numberOfRooms
                            }`
                          );
                        }}
                      >
                        <option>בחר סוג חדר</option>
                        {roomTypes.map((roomType) => {
                          return (
                            <option key={roomType._id} value={roomType.name}>
                              {`${roomType.name} ב ${roomType.numberOfRooms} חדרים`}
                            </option>
                          );
                        })}
                      </Field>
                      <label htmlFor="">מספר אורחים</label>
                      <Field
                        name={`plans[${idx}].room.numberOfGuests`}
                        value={values.plans[idx].room.numberOfGuests || ''}
                      />
                      <label htmlFor="">מספר חדרים</label>
                      <Field
                        name={`plans[${idx}].room.numberOfRooms`}
                        value={values.plans[idx].room.numberOfRooms || ''}
                      />
                      <label htmlFor="">פעיל</label>
                      <Field
                        type="checkbox"
                        name={`plans[${idx}].isActive`}
                        id={`plans[${idx}].isActive`}
                      />
                      <hr />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          setFieldValue('plans', [
                            ...values.plans,
                            {
                              notes: '',
                              price: '',
                              room: {
                                name: '',
                                numberOfRooms: '',
                                numberOfGuests: '',
                              },

                              isActive: true,
                            },
                          ]);
                        }}
                      >
                        הוסף תוכנית
                      </button>
                      <button
                        className="
                        btn btn-danger"
                        type="button"
                        onClick={() => {
                          return setFieldValue(
                            'plans',
                            removePlan(values.plans, idx)
                          );
                        }}
                      >
                        מחק תוכנית
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <button
              className="
            btn btn-primary"
              type="submit"
            >
              שמור
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};
export default EditTrip;

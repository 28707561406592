import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const CustomAlert = ({ title, message, buttons, show }) => {
  const onSubmit = () => {
    console.log('submit');
    confirmAlert({
      title: title,
      message: message,
      buttons: buttons,
    });
  };

  useEffect(() => {
    if (show) {
      onSubmit();
    }
  }, [show, onSubmit]);

  return null;
};
export default CustomAlert;

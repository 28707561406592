import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../features/users/usersSlice';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';

function UserItem(props) {
  return (
    <div onClick={() => props.handleSelect(props.user._id)}>
      {props.user.firstName} {props.user.lastName} {props.user.employeeId}
    </div>
  );
}

const UsersList = ({ handleSelect }) => {
  const { isLoading, users } = useSelector((state) => state.users);
  const [users2, setUsers] = useState([]);
  const [showList, setShowList] = useState(false);

  const handleSearch = (e) => {
    const search = e.target.value;
    const searchFields = ['firstName', 'lastName', 'employeeId'];
    const filteredUsers = users2.filter((user) => {
      return searchFields.some((field) => {
        return user[field]
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    });

    if (search.length > 0) {
      setUsers(filteredUsers);
    } else setUsers(users);

    if (filteredUsers.length === 0 && search) {
      toast.error('לא נמצא עובד עם פרטים כאלה');
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers())
      .unwrap()
      .then((res) => {
        setUsers(res);
      })
      .catch((err) => toast.error(err.message));

    // console.log(users2);10
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="container">
        <h2>חיפוש</h2>
        <div className="form-group search">
          <input
            className="form-control"
            type="text"
            onChange={handleSearch}
            label="חפש לפי מספר עובד או שם עובד"
            placeholder="חיפוש עובד ..."
            name="search"
            onClick={() => setShowList(true)}
          />

          {showList === true &&
            users2.map((user, idx) => {
              if (idx < 10)
                return (
                  <UserItem
                    key={user._id}
                    user={user}
                    handleSelect={handleSelect}
                  />
                );
            })}
          {/* <select
            className="form-control"
            onChange={handleSelect}
            value={user ? user._id : ''}
          >
            <option value="">בחר עובד</option>

            {users2.map((user) => {
              return (
                <option key={user._id} value={user._id}>
                  {user.firstName} {user.lastName} {user.employeeId}
                </option>
              );
            })}
          </select> */}
        </div>
      </div>
    </>
  );
};
export default UsersList;

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setTrip } from '../features/trips/tripsSlice';
import TripsList from '../pages/user/TripsList';

const TripsReg = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

  const handleSelect = (trip) => {
    dispatch(setTrip(trip));
    navigate(`/ChooseCycle`);
  };
  return <TripsList handleSelect={handleSelect} />;
};
export default TripsReg;

import { FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from '../features/auth/authSlice';
import { removeUser } from '../features/users/usersSlice';
import logoSvg from '../assets/logo.svg';

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: AuthUser } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.users);
  const onLogout = () => {
    dispatch(logOut());
    navigate('/');
    window.location.reload();
  };

  const logoutUser = () => {
    dispatch(removeUser());
    navigate('main');
  };

  const usersSection = () => {
    if (AuthUser && !user) {
      return (
        <>
          <div>{`שלום ${AuthUser.firstName} ${AuthUser.lastName}`}</div>
          <div></div>
        </>
      );
    } else {
      return (
        <>
          <div>
            {`שלום ${AuthUser.firstName} ${AuthUser.lastName}`}
            <div> {`מחובר כ${user.firstName} ${user.lastName}`}</div>
          </div>
          <div>
            {AuthUser.roles.includes('admin') && (
              <>
                <button className="btn" onClick={logoutUser}>
                  {`התנתק מ${user.firstName} ${user.lastName}`}
                </button>
              </>
            )}
          </div>
        </>
      );
    }
  };
  const url = window.location.pathname;
  return (
    <header className="header">
      <div className="logo" onClick={() => navigate('main')}>
        <img src={logoSvg} alt="logo" />
      </div>

      {AuthUser && usersSection()}
      {url != '/main' && url != '/' ? (
        <button className="btn" onClick={() => navigate(-1)}>
          חזור
        </button>
      ) : (
        <div></div>
      )}

      <ul>
        {AuthUser ? (
          <li>
            <button className="btn" onClick={onLogout}>
              <FaSignOutAlt />
              התנתק
            </button>
          </li>
        ) : null}
      </ul>
    </header>
  );
}

export default Header;

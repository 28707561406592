import { useDispatch, useSelector } from 'react-redux';
import { deleteCycle, editCycle } from '../../features/trips/tripsSlice';
import CycleForm from '../../components/CycleForm';
import { toast } from 'react-toastify';

const EditCycle = () => {
  const { cycle } = useSelector((state) => state.trips);
  const dispatch = useDispatch();

  const Submit = (values) => {
    const payload = {
      departDate: values.departDate,
      returnDate: values.returnDate,
      days: values.days,
      nights: values.nights,
      notes: values.notes,
      availableSlots: values.availableSlots,
    };

    dispatch(editCycle(payload))
      .unwrap()
      .then(() => {
        toast.success('המחזור עודכן בהצלחה');
      });
  };

  const Delete = () => {
    dispatch(deleteCycle())
      .unwrap()
      .then(() => {
        toast.success('המחזור נמחק בהצלחה');
      });
  };

  const initialValues = {
    departDate: new Date(cycle.departDate).toISOString().slice(0, 10),
    returnDate: new Date(cycle.returnDate).toISOString().slice(0, 10),
    days: cycle.days,
    nights: cycle.nights,
    notes: cycle.notes,
    availableSlots: cycle.availableSlots,
  };

  return (
    <CycleForm
      initialValues={initialValues}
      Submit={Submit}
      onDelete={Delete}
      btnText="ערוך מחזור"
    />
    // <div className="">
    //   <Formik
    //     initialValues={{
    //       departDate: new Date(cycle.departDate).toISOString().slice(0, 10),
    //       returnDate: new Date(cycle.returnDate).toISOString().slice(0, 10),
    //       days: cycle.days,
    //       nights: cycle.nights,
    //       notes: cycle.notes,
    //       availableSlots: cycle.availableSlots,
    //     }}
    //     onSubmit={(values, { setSubmitting }) => {
    //       setSubmitting(true);
    //       // make async call
    //       console.log(values);

    //       const payload = {
    //         departDate: values.departDate,
    //         returnDate: values.returnDate,
    //         days: values.days,
    //         nights: values.nights,
    //         notes: values.notes,
    //         availableSlots: values.availableSlots,
    //       };

    //       dispatch(editCycle(payload));

    //       setSubmitting(false);
    //     }}
    //   >
    //     {({ isSubmitting, errors, touched, isValid }) => (
    //       <Form>
    //         <div className="form-container">
    //           <div className="form-group">
    //             <label htmlFor="departDate">תאריך יציאה</label>
    //             <Field type="date" name="departDate" />
    //             {errors.departDate &&
    //             touched.departDate &&
    //             errors.departDate ? (
    //               <div className="input-feedback">{errors.departDate}</div>
    //             ) : null}

    //             <label htmlFor="returnDate">תאריך חזרה</label>
    //             <Field type="date" name="returnDate" />
    //             {errors.returnDate &&
    //             touched.returnDate &&
    //             errors.returnDate ? (
    //               <div className="input-feedback">{errors.returnDate}</div>
    //             ) : null}

    //             <label htmlFor="days">מספר ימים</label>
    //             <Field type="number" name="days" />
    //             {errors.days && touched.days && errors.days ? (
    //               <div className="input-feedback">{errors.days}</div>
    //             ) : null}

    //             <label htmlFor="nights">מספר לילות</label>
    //             <Field type="number" name="nights" />
    //             {errors.nights && touched.nights && errors.nights ? (
    //               <div className="input-feedback">{errors.nights}</div>
    //             ) : null}

    //             <label htmlFor="notes">הערות</label>
    //             <Field type="text" name="notes" />
    //             {errors.notes && touched.notes && errors.notes ? (
    //               <div className="input-feedback">{errors.notes}</div>
    //             ) : null}

    //             <label htmlFor="availableSlots">מספר מקומות פנויים</label>
    //             <Field type="number" name="availableSlots" />
    //             {errors.availableSlots &&
    //             touched.availableSlots &&
    //             errors.availableSlots ? (
    //               <div className="input-feedback">{errors.availableSlots} </div>
    //             ) : null}
    //           </div>
    //         </div>
    //         <div className="buttons-container">
    //           <button
    //             className="btn"
    //             type="submit"
    //             disabled={isSubmitting || !isValid}
    //           >
    //             עדכן
    //           </button>

    //           <button
    //             className="btn"
    //             type="button"
    //             onClick={() => {
    //               navigate(-1);
    //             }}
    //           >
    //             ביטול
    //           </button>

    //           <button
    //             className="btn"
    //             type="button"
    //             onClick={() => {
    //               dispatch(deleteCycle());
    //             }}
    //           >
    //             הסר מחזור
    //           </button>
    //         </div>
    //       </Form>
    //     )}
    //   </Formik>
    // </div>
  );
};
export default EditCycle;

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { login } from '../features/auth/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../components/Spinner';
const Login = () => {
  const [formData, setFormData] = useState({
    empId: '',
    password: '',
  });

  const { empId, password } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, errorMessage } = useSelector((state) => state.auth);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = { id: empId, password };
    dispatch(login(userData))
      .unwrap()
      .then((result) => {
        if (result) {
          navigate('/main');
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(errorMessage);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <section className="heading">
        <h1>
          התחבר
          {/* <FaSignInAlt /> התחבר */}
        </h1>
        <p>התחבר כדי להמשיך למערכת</p>
      </section>

      <section className="form">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <input
              type="text"
              placeholder="מספר עובד"
              name="empId"
              value={empId}
              id="empId"
              onChange={onChange}
              required={true}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="סיסמא"
              name="password"
              value={password}
              id="password"
              onChange={onChange}
              required={true}
            />
          </div>
          <div className="form-group">
            <button className="btn btn-block loginBtn">התחבר</button>
          </div>
        </form>
      </section>
    </>
  );
};
export default Login;

const TripItem = ({ trip }) => {
  const lastSignupDate = `תאריך אחרון להרשמה: ${new Date(
    trip.lastSignupDate
  ).toLocaleDateString()}`;

  const initialValue = trip.cycles.reduce((acc, cycle) => {
    return acc + cycle.availableSlots;
  }, 0);

  const availableSeats = trip.cycles.reduce((acc, cycle) => {
    return acc - cycle.numberOfSubscribers;
  }, initialValue);

  const availableSeatsText = `מקומות פנויים: ${availableSeats}`;

  return (
    <div className="trip-item">
      <div className="trip-item__header">
        <h3>{trip.tripName}</h3>
        {trip.lastSignupDate && <p>{lastSignupDate}</p>}
        {trip.cycles && <p>{availableSeatsText}</p>}
      </div>
    </div>
  );
};
export default TripItem;

import { configureStore } from '@reduxjs/toolkit';

import authReducer from '../features/auth/authSlice';
import reportsReducer from '../features/reports/reportSlice';
import organizationReducer from '../features/organization/organizationSlice';
import usersReducer from '../features/users/usersSlice';
import tripsReducer from '../features/trips/tripsSlice';
import logsReducer from '../features/logs/logsSlice';
import { combineReducers } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'organization', 'trips', 'users'],
  version: 1,
};

const rootReducer = combineReducers({
  auth: authReducer,
  organization: organizationReducer,
  users: usersReducer,
  trips: tripsReducer,
  reports: reportsReducer,
  logs: logsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import {
  changeRegOptions,
  getRegOptions,
  getRideSetting,
  getVoucherEligibilities,
  organizationSelector,
  updateRideSetting,
} from '../../features/organization/organizationSlice';


const Admin = () => {
  const [voucherEligibilities, setVoucherEligibilities] = useState([]);
  const [isRideForAll, setIsRideForAll] = useState(false);
  const dispatch = useDispatch();
  const { regOptions, isLoading } = useSelector(organizationSelector);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!regOptions.length) {
      dispatch(getRegOptions())
        .unwrap()
        .then((res) => {
          setOptions(res.data);
        });
    } else setOptions(regOptions);
  }, [regOptions.length, dispatch]);

  useEffect(() => {
    dispatch(getVoucherEligibilities())
      .unwrap()
      .then((res) => {
        setVoucherEligibilities(res.data);
        console.log(res.data);
      });

    dispatch(getRideSetting())
      .unwrap()
      .then((res) => {
        setIsRideForAll(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);

  const handleRegOptionsSubmit = (e) => {
    e.preventDefault();
    dispatch(changeRegOptions(options));
  };

  const handleOnChange = (option) => {
    const newOption = { ...option, isActive: !option.isActive };
    const newOptions = options.map((option) => {
      if (option._id === newOption._id) {
        return newOption;
      }
      return option;
    });
    setOptions(newOptions);
  };

  if (isLoading) return <Spinner />;

  return (
    <div className="container">
      <h1 className="heading">פנאל ניהול</h1>
      <div className="">
        <h2>עריכת אפשרויות הרשמה</h2>
        <form onSubmit={handleRegOptionsSubmit}>
          {options.length > 0 &&
            options.map((option) => {
              return (
                <div key={option._id} className="form-container">
                  <label>{option.name}</label>
                  <input
                    name={option.name}
                    type="checkbox"
                    checked={option.isActive}
                    onChange={() => handleOnChange(option)}
                  />
                </div>
              );
            })}

          <button type="submit" className="btn btn-main">
            עדכן
          </button>
        </form>
      </div>

      <div className="">
        <h2>עריכת זכאות לוואוצ׳ר</h2>
        {
          //todo - add a form to change the amount of money a user needs to pay in order to register to a trip
        }

        <Formik
          initialValues={{
            voucherEligibilitiesOptions: voucherEligibilities,
            amount: voucherEligibilities[0]?.amount,
          }}
          onSubmit={(values) => {
            console.log(values);
            // dispatch(changeRegOptions(values));
          }}
        >
          {({
            values,
            isValid,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            handleBlur,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>זכאות לוואוצ׳ר</label>
                  <Field
                    as="select"
                    name="voucherEligibilitiesOptions"
                    className="form-control"
                    onChange={(e) => {
                      setFieldValue(
                        'voucherEligibilitiesOptions',
                        e.target.value
                      );
                    }}
                    handleBlur={handleBlur}
                  >
                    {voucherEligibilities.map((option) => {
                      return (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Field>
                  <label>סכום</label>
                  <Field
                    type="text"
                    name="amount"
                    className="form-control"
                    value={
                      voucherEligibilities.find(
                        (option) =>
                          option._id === values.voucherEligibilitiesOptions
                      )?.amount || voucherEligibilities[0]?.amount
                    }
                    onChange={(e) => {
                      setFieldValue('amount', e.target.value);
                    }}
                    handleBlur={handleBlur}
                  />

                  <button
                    type="submit"
                    className="btn btn-main"
                    disabled={!isValid || isSubmitting}
                  >
                    עדכן
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div className="">
        <h1>הגדרות כלליות</h1>
        <Formik
          initialValues={{
            ridesForAllTrips: isRideForAll,
          }}
          onSubmit={(values) => {
            const { ridesForAllTrips } = values;

            setIsRideForAll(ridesForAllTrips);

            dispatch(updateRideSetting(ridesForAllTrips))
              .unwrap()
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });

          }}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>הסעות לכל הטיולים</label>
                  <Field
                    type="checkbox"
                    name="ridesForAllTrips"
                    className="form-control"
                    onChange={(e) => {
                      setIsRideForAll(e.target.checked);
                    }}
                    checked={values.ridesForAllTrips}
                  />
                  <button
                    type="submit"
                    className="btn btn-main"
                    disabled={isSubmitting}
                  >
                    עדכן
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <button className="btn btn-main" onClick={() => navigate('/logs')}>
        הצג לוגים
      </button>
    </div>
  );
};
export default Admin;

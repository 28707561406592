import { useEffect } from 'react';
import {
  getVoucherAmount,
  getVouchersList,
} from '../../features/organization/organizationSlice';
import { useSelector, useDispatch } from 'react-redux';
import { organizationSelector } from '../../features/organization/organizationSlice';
import Spinner from '../../components/Spinner';
import { signUpForVoucher } from '../../features/trips/tripsSlice';
import { toast } from 'react-toastify';

const Voucher = () => {
  const dispatch = useDispatch();
  const { voucherAmount, isLoading, vouchersList } =
    useSelector(organizationSelector);
  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getVoucherAmount(user._id))
      .unwrap()
      .catch((err) => {
        toast.error(err.message);
      });
  }, [user._id, voucherAmount, dispatch]);

  useEffect(() => {
    if (!vouchersList.length)
      dispatch(getVouchersList(user._id))
        .unwrap()
        .catch((err) => {
          toast.error(err.message);
        });
  }, [vouchersList.length, user._id, dispatch]);

  const onAgencySelect = (item) => {
    dispatch(signUpForVoucher(item)).catch((err) => {
      toast.error(err.message);
    });
  };

  if (isLoading) return <Spinner />;
  return (
    <div className="container">
      <h1 className="heading">בחר/י סוכנות</h1>
      <h2 className="heading">{`זכאותך הינה: ${voucherAmount} ש"ח`}</h2>
      {vouchersList && (
        <div className="row-container">
          {vouchersList.map((voucher) => {
            return (
              <div key={voucher._id} className="voucher">
                <button className="btn" onClick={() => onAgencySelect(voucher)}>
                  {voucher.name}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default Voucher;

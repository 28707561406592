import api from '../../App/api';
const prefix = `/trips`;

const getTrips = async (season) => {
  const response = await api.get(`${prefix}/all/${season}`);
  return response.data;
};

const getTrip = async (id) => {
  const response = await api.get(`${prefix}/${id}`);

  return response.data;
};

const createTrip = async (payload) => {
  const response = await api.post(`${prefix}/addTrip`, payload);
  return response.data;
};

const updateTrip = async (trip) => {
  return await api.put(`${prefix}/update`, trip);
};

const registerToTrip = async (payload) => {
  const response = await api.post(`${prefix}/register`, payload);
  return response.data;
};

const editCycle = async (payload, id) => {
  const response = await api.post(`${prefix}/editCycle/${id}`, payload);
  return response.data;
};

const deleteCycle = async (id) => {
  const response = await api.post(`${prefix}/deleteCycle/${id}`, {});
  return response.data;
};

const getRoomTypes = async () => {
  const response = await api.get(`${prefix}/roomTypes`);
  return response.data;
};

const signUpForVoucher = async (payload, season) => {
  const response = await api.post(`${prefix}/signUpForVoucher`, {
    season,
    voucherOption: payload,
  });
  return response.data;
};

const getRegisteredUsersPerTrip = async (tripId, season, cycleId, year) => {
  const response = await api.post(`${prefix}/getRegisteredUsersPerTrip`, {
    tripId,
    season,
    cycle: cycleId,
    year,
  });
  return response.data;
};

const generateTripReport = async (payload) => {
  const response = await api.post(`${prefix}/generateTripReport`, payload);
  return response.data;
};

const checkIfTripHasSignup = async (tripId) => {
  const response = await api.post(`${prefix}/checkForSignUps`, { tripId });
  return response.data;
};

const getRegisteredUsersPerCycle = async (cycleId) => {
  const response = await api.post(`${prefix}/getRegisteredUsersPerCycle`, {
    cycleId,
  });
  return response.data;
};

const registerUserForRefund = async (payload) => {
  const { season, userId } = payload;
  const response = await api.post(`${prefix}/refund`, {
    season,
    userId,
  });
  return response.data;
};

const archiveTrip = async (tripId) => {
  const response = await api.post(`${prefix}/archiveTrip`, { tripId });
  return response.data;
};

const tripsService = {
  getTrips,
  getTrip,
  createTrip,
  updateTrip,
  registerToTrip,
  editCycle,
  deleteCycle,
  getRoomTypes,
  signUpForVoucher,
  getRegisteredUsersPerTrip,
  generateTripReport,
  checkIfTripHasSignup,
  getRegisteredUsersPerCycle,
  registerUserForRefund,
  archiveTrip,
};

export default tripsService;

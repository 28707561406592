import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import {
  getSeasons,
  organizationSelector,
  setSeason,
} from '../../features/organization/organizationSlice';
import { setUser } from '../../features/users/usersSlice';

const SelectSeason = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { seasons, isLoading } = useSelector(organizationSelector);
  const { user } = useSelector((state) => state.auth);
  const { user: currentUser } = useSelector((state) => state.users);

  useEffect(() => {
    if (seasons.length === 0) {
      dispatch(getSeasons())
        .unwrap()
        .catch((err) => {
          toast.error(err.message);
        });
    }
  });
  useEffect(() => {
    if (user && !currentUser) {
      dispatch(setUser(user));
    }
  }, [user, dispatch, currentUser]);

  const handleSelectSeason = (season) => {
    console.log(season);
    dispatch(setSeason(season));
    navigate(`/registration`);
  };
  const generateReport = () => {
    //make api call to generate report
  };

  const goToEditWorker = () => {
    console.log('go to edit worker');
    navigate(`/user/${user._id}`);
  };

  return (
    <div className="container">
      <h2>בחר עונה</h2>
      {isLoading && <Spinner />}
      <ul className="list-group">
        {seasons.map((season) => (
          <li className="item" key={season}>
            <div onClick={() => handleSelectSeason(season)}>{season}</div>
          </li>
        ))}
      </ul>
      <button className="btn btn-main" onClick={goToEditWorker}>
        עריכת פרטים אישיים
      </button>
      <button className="btn btn-main" onClick={generateReport}>
        דו״ח עובד
      </button>
    </div>
  );
};
export default SelectSeason;

import { useField } from 'formik';
import React from 'react';
//this is a custom checkbox component that uses the useField hook from formik
const CustomInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-group">
      <label htmlFor={props.id || props.name}>{label}</label>
      <input
        {...field}
        {...props}
        className={meta.touched && meta.error ? 'input-error' : ''}
      />
      {meta.touched && meta.error && (
        <div className="input-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default CustomInput;

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function RideAddressDialog({
  handleClose,
  handleSubmit,
  text,
  open,
  rideAddress,
  handleChange,
}) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>הסעה</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="rideAddress"
            label="כתובת הסעה"
            type="text"
            fullWidth
            variant="standard"
            value={rideAddress}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>שלח</Button>
          <Button onClick={handleClose}>בטל</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

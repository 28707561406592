import api from '../../App/api';

const prefix = '/logs';

const getLogs = async (limit, page) => {
  const response = await api.get(`${prefix}/${page}/${limit}`);
  return response.data;
};

const getLogsByUser = async (limit, page, userId) => {
  const response = await api.get(`${prefix}/${page}/${limit}/${userId}`);
  return response.data;
};

const logsService = {
  getLogs,
  getLogsByUser,
};

export default logsService;

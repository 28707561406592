import api from '../../App/api';
const prefix = '/organizations';

const addSeason = async (season, organization, token) => {
  const response = await api.post(`${prefix}/addSeasons`, {
    newSeason: season,
    id: organization,
  });
  return response.data;
};

const deleteSeason = async (season, organization, token) => {
  const response = await api.delete(`${prefix}/deleteSeason`, {
    removedSeason: season,
    id: organization,
  });

  return response.data;
};

const getSeasons = async (organizationId, token) => {
  const response = await api.get(`${prefix}/seasons/${organizationId}`);
  return response.data;
};

const getReportsList = async (organizationId, token) => {
  const response = await api.get(`${prefix}/reports/${organizationId}`);
  return response.data;
};

const getReport = (organizationId, reportId, token) => {
  return api.get(organizationId + '/reports/' + reportId);
};

const getRegOptions = async () => {
  const res = await api.get(`${prefix}/regOptions`);

  return res.data;
};

const changeRegOptions = async (options) => {
  const res = await api.post(`${prefix}/changeRegOptions`, {
    options,
  });

  return res.data;
};

const getVoucherAmount = async (id) => {
  const res = await api.post(`${prefix}/voucherAmount`, { id });

  return res.data;
};

const getVouchersList = async (id) => {
  const res = await api.post(`${prefix}/voucherList`, { id });

  return res.data;
};

const getVoucherEligibilities = async () => {
  const response = await api.get(`${prefix}/getVoucherEligibilities`);
  return response.data;
};

const changeVoucherEligibilityPrice = (id, price) => {
  const response = api.post(`${prefix}/changeVoucherEligibilityPrice`, {
    id,
    price,
  });
  return response.data;
};

const getRideSetting = async () => {
  const response = await api.post(`${prefix}/getRideSetting`);
  return response.data;
};

const updateRideSetting = async (payload) => {
  const response = await api.post(`${prefix}/setRideSetting`, payload);
  return response.data;
};

const organizationService = {
  addSeason,
  getSeasons,
  getReportsList,
  getReport,
  deleteSeason,
  getRegOptions,
  changeRegOptions,
  getVoucherAmount,
  getVouchersList,
  getVoucherEligibilities,
  changeVoucherEligibilityPrice,
  getRideSetting,
  updateRideSetting,
};

export default organizationService;
